import React, { HTMLAttributes } from 'react'
import { IMarkerProps, MapType } from '../../map.types'
import maplibregl, { LngLatLike, Marker as MarkerType } from 'maplibre-gl'
import { useMapLibre } from '../Provider'
import Tooltip from '../Tooltip'
import ReactDOM from 'react-dom/client'
import Popup from '../Popup'
import { DeleteWindow } from '@shared/innmaxMap/components/Window'
import MapLibreMarkerProvider from './MarkerProvider'
import { css } from 'styled-components'
import { isNil } from 'ramda'

type IMapMarkerPosition =
  | { lat: number | null | undefined; lon: number | null | undefined }
  | { x: number | null | undefined; y: number | null | undefined }

export interface IEnhanceMarkerRef extends MarkerType {
  setPosition: (position: IMapMarkerPosition) => void
}

function isLatLon(
  position: IMapMarkerPosition
): position is { lat: number; lon: number } {
  if (
    position &&
    'lat' in position &&
    'lon' in position &&
    !isNil(position.lat) &&
    !isNil(position.lon)
  ) {
    return true
  }
  return false
}

function isXY(
  position: IMapMarkerPosition
): position is { x: number; y: number } {
  if (
    position &&
    'x' in position &&
    'y' in position &&
    !isNil(position.x) &&
    !isNil(position.y)
  ) {
    return true
  }
  return false
}

export function isValidMarkerPosition(item: any): item is IMapMarkerPosition {
  return isLatLon(item as any) || isXY(item as any)
}

const Marker = React.forwardRef<
  IEnhanceMarkerRef | null,
  React.PropsWithChildren<IMarkerProps>
>((props, ref) => {
  const {
    onLeftClick,
    onRightClick,
    draggable,
    deletable,
    focus,
    selected,
    onDragEnd,
    onDelete,
    onDrag,
    tooltipContent,
    children,
    defaultPosition,
    baseZIndex = 1,
  } = props

  const {
    map,
    projectRatioPointsToLngLat,
    projectToRatioPoints,
    mapType,
    imageMapBounds,
  } = useMapLibre()

  const [marker, setMarker] = React.useState<MarkerType | null>(null)
  const [visibleDeletePopup, setVisibleDeletePopup] = React.useState(false)

  const reactDomRootRef = React.useRef<ReactDOM.Root | null>(null)

  // handle focus
  React.useEffect(() => {
    marker?.toggleClassName('icon-animation')
  }, [focus])

  // handle selected
  React.useEffect(() => {
    marker?.toggleClassName('selected')
  }, [selected])

  React.useEffect(() => {
    marker?.setDraggable(draggable)
  }, [draggable])

  const getLngLatByPosition = React.useCallback(
    (mapType: MapType, position: IMapMarkerPosition): LngLatLike | null => {
      let markerPosition: LngLatLike | null = null
      if (mapType === MapType.GoogleMap && isLatLon(position)) {
        markerPosition = {
          lat: position.lat,
          lon: position.lon,
        }
      }
      if (mapType === MapType.Upload && isXY(position)) {
        markerPosition = projectRatioPointsToLngLat({
          x: position.x,
          y: position.y,
        })
      }

      if (markerPosition?.lat < -90 || markerPosition?.lat > 90) {
        return {
          lat: 0,
          lon: 0,
        }
      }
      return markerPosition
    },
    [projectRatioPointsToLngLat]
  )

  React.useEffect(() => {
    if (!marker) {
      return
    }
    const enhanceMarker: IEnhanceMarkerRef | null = marker
      ? Object.assign(marker, {
          setPosition: (position: IMapMarkerPosition) => {
            const p = getLngLatByPosition(mapType, position)
            p && marker.setLngLat(p)
          },
        })
      : null

    if (typeof ref === 'function') {
      ref(enhanceMarker)
    } else if (ref) {
      ref.current = enhanceMarker
    }
  }, [getLngLatByPosition, mapType, marker, ref])

  React.useEffect(() => {
    if (!marker || !map) {
      return
    }
    const handleClick = (e: MouseEvent) => {
      switch (e.button) {
        case 0:
          onLeftClick && onLeftClick()
          break
        default:
          break
      }
    }

    const handleContextmenu = (e: MouseEvent) => {
      onRightClick && onRightClick()
      if (!deletable) {
        return
      }
      setVisibleDeletePopup(true)
    }

    const handleDisableRightBtnDrag = (e: MouseEvent) => {
      if (e.button === 2 && draggable) {
        e.preventDefault()
        e.stopPropagation()
      }
    }

    const markerElement = marker.getElement()

    markerElement.addEventListener('click', handleClick)
    markerElement.addEventListener('contextmenu', handleContextmenu)
    markerElement.addEventListener('mousedown', handleDisableRightBtnDrag)

    return () => {
      markerElement.removeEventListener('click', handleClick)
      markerElement.removeEventListener('contextmenu', handleContextmenu)
      markerElement.removeEventListener('mousedown', handleDisableRightBtnDrag)
    }
  }, [deletable, draggable, map, marker, onLeftClick, onRightClick])

  React.useEffect(() => {
    if (!marker) {
      return
    }
    const markerElement = marker.getElement()

    const handleMouseOver = () => {
      markerElement.style.zIndex = baseZIndex + 1 + ''
    }

    const handleMouseOut = () => {
      markerElement.style.zIndex = baseZIndex + ''
    }

    markerElement.addEventListener('mouseover', handleMouseOver)
    markerElement.addEventListener('mouseout', handleMouseOut)
    return () => {
      markerElement.removeEventListener('mouseover', handleMouseOver)
      markerElement.removeEventListener('mouseout', handleMouseOut)
    }
  }, [marker, baseZIndex])

  React.useEffect(() => {
    if (!marker || !map) {
      return
    }
    const handleDragEnd = () => {
      if (!onDragEnd) {
        return
      }
      if (mapType === MapType.GoogleMap) {
        onDragEnd({
          lat: Number(marker.getLngLat().lat.toFixed(15)),
          lon: Number(marker.getLngLat().lng.toFixed(15)),
        })
      }

      if (mapType === MapType.Upload && imageMapBounds) {
        const imgBounds = new maplibregl.LngLatBounds(
          imageMapBounds[1],
          imageMapBounds[0]
        )

        if (!imgBounds.contains(marker.getLngLat())) {
          if (marker.getLngLat().lat > imgBounds.getNorth()) {
            marker.setLngLat([marker.getLngLat().lng, imgBounds.getNorth()])
          }
          if (marker.getLngLat().lat < imgBounds.getSouth()) {
            marker.setLngLat([marker.getLngLat().lng, imgBounds.getSouth()])
          }
          if (marker.getLngLat().lng < imgBounds.getEast()) {
            marker.setLngLat([imgBounds.getEast(), marker.getLngLat().lat])
          }
          if (marker.getLngLat().lng > imgBounds.getWest()) {
            marker.setLngLat([imgBounds.getWest(), marker.getLngLat().lat])
          }
        }
        const point = projectToRatioPoints({
          x: map.project(marker.getLngLat()).x,
          y: map.project(marker.getLngLat()).y,
        })
        onDragEnd({
          x: point.x,
          y: point.y,
        })
      }
    }

    const handleDrag = () => {
      onDrag && onDrag()
    }

    const handleDragStart = () => {
      setVisibleDeletePopup(false)
    }

    marker.on('dragstart', handleDragStart)
    marker.on('drag', handleDrag)
    marker.on('dragend', handleDragEnd)
    return () => {
      marker.on('dragstart', handleDragStart)
      marker.off('drag', handleDrag)
      marker.off('dragend', handleDragEnd)
    }
  }, [map, mapType, marker, onDragEnd, onDrag, imageMapBounds])

  React.useEffect(() => {
    if (!map) {
      return
    }

    let iconChild = null
    React.Children.forEach(children, child => {
      if (React.isValidElement(child) && child.type === MarkerIconDiv) {
        iconChild = child
      }
    })

    if (!iconChild) {
      console.error(
        '[mapLibre error], 無對應的 Marker, 請使用 Marker.MarkerIconDiv 設定 Icon'
      )
      return
    }

    const rootEl = document.createElement('div')
    rootEl.style.cursor = 'pointer'
    rootEl.style.zIndex = baseZIndex + ''

    reactDomRootRef.current = ReactDOM.createRoot(rootEl)

    reactDomRootRef.current.render(iconChild)

    const _marker = new maplibregl.Marker({
      element: rootEl,
      draggable,
    })

    let markerPosition: LngLatLike | null = getLngLatByPosition(
      mapType,
      defaultPosition as any
    )

    if (markerPosition) {
      _marker.setLngLat(markerPosition)
      _marker.addTo(map)
      setMarker(_marker)
    }

    return () => {
      _marker.remove()
    }
  }, [map, getLngLatByPosition])

  React.useEffect(() => {
    reactDomRootRef.current?.render(children)
  }, [children])

  return (
    <MapLibreMarkerProvider marker={marker}>
      {tooltipContent && <Tooltip>{tooltipContent}</Tooltip>}
      {React.Children.map(children, child => {
        if (React.isValidElement(child) && child.type !== MarkerIconDiv) {
          return child
        }
        return null
      })}
      {visibleDeletePopup && (
        <Popup visible onClose={() => setVisibleDeletePopup(false)}>
          <DeleteWindow
            css={css`
              margin-left: 0px;
            `}
            onCancel={() => {
              setVisibleDeletePopup(false)
            }}
            deleteMarker={() => onDelete && onDelete()}
          />
        </Popup>
      )}
    </MapLibreMarkerProvider>
  )
})

const MarkerIconDiv = (props: HTMLAttributes<HTMLDivElement>) => (
  <div {...props} />
)

const Utils = {
  isValidMarkerPosition,
}

export default Object.assign(Marker, { MarkerIconDiv, Utils })
