import { RefProps } from '@lib/types'
import { DatePicker } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker'
import cx from 'classnames'
import React from 'react'
import styled from 'styled-components'

type Props = RangePickerProps & RefProps

const RangePicker = React.forwardRef<typeof DatePicker.RangePicker, Props>(
  ({ className = '', ...props }, ref) => (
    <RangeStyledPicker
      forwardRef={ref}
      className={cx('c-datepicker', className)}
      {...props}
    />
  )
)

const RangeStyledPicker = styled(DatePicker.RangePicker)`
  &.ant-picker {
    border-radius: 4px;
    background-color: ${p => p.theme.datePicker.bg};
    border-color: ${p => p.theme.datePicker.borderColor};
    &:hover,
    &:focus {
      border-color: ${p => p.theme.datePicker.hoverAndFocusBorderColor};
    }
    > .ant-picker-active-bar {
      background: ${p => p.theme.datePicker.rangeActiveBarBg};
    }
    .ant-picker-separator {
      color: ${p => p.theme.datePicker.placeholderColor};
    }
    > .ant-picker-input {
      input {
        ::placeholder {
          color: ${p => p.theme.datePicker.placeholderColor};
        }
        color: ${p => p.theme.datePicker.color};
      }
    }
    &.ant-picker-disabled {
      background: ${p => p.theme.datePicker.disabledBg};
      color: ${p => p.theme.datePicker.disabledColor};
    }
    > .ant-picker-clear svg[data-icon='close-circle'] {
      fill: ${p => p.theme.datePicker.clearIconFill};
    }
    > .ant-picker-suffix svg[data-icon='calendar'] {
      fill: ${p => p.theme.datePicker.calendarIconFill};
    }
  }
`

export default RangePicker
