import { Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { TYPE } from '@services/report'
import type { ICommonTitle } from './type'

export default function ChartTitle({
  yAxisDateStr,
  sessionName,
  queryVariables,
  yAxisName,
}: ICommonTitle) {
  const { t } = useTranslation()

  return (
    <Col
      span={24}
      className="text-largest font-medium text-normal  flex justify-center">
      {queryVariables?.reportType === TYPE.MONTH
        ? `${new Date().getFullYear()}${t('report:year')}${yAxisDateStr}`
        : yAxisDateStr}
      {sessionName ? `${sessionName} ` : ''}
      {queryVariables?.deviceType
        ? t(`device:select-${queryVariables?.deviceType}`)
        : ''}
      {yAxisName}
    </Col>
  )
}
