import maplibregl, { IControl, LngLat, LngLatLike, Map } from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { always, cond, equals } from 'ramda'
import React from 'react'
import { css } from 'styled-components'
import { IMapLibreContainerProps, MapType } from '../map.types'
import { useInnmaxMap } from '../Provider'
import { FilterControl } from './FilterControl'
import { LayerControl } from './LayerControl'
import { IMapLibreMapMode, useMapLibre } from './Provider'
import { IControlClass } from './type'

const canvasId = 'upload_map_canvas'

const MapLibreContainer = React.forwardRef<
  HTMLDivElement | null,
  React.PropsWithChildren<IMapLibreContainerProps>
>((props, ref) => {
  const {
    containerId = 'innmax_maplibre_view',
    center,
    zoom,
    mapContainerStyle,
    maxZoom = 24,
    minZoom = 0,
    mapType = MapType.GoogleMap,
    layerFileUrl,
    children,
    controlsOption,
    onFilterControlClick,
    onLayerControlClick,
  } = props

  const { setMaplibreMap, defaultCenter, defaultZoom } = useInnmaxMap()

  const { mapMode, onLoad, map, setImageMapBounds, setMapType } = useMapLibre()

  useControls(
    'Filter',
    controlsOption?.filterControlOptions
      ? {
          ...controlsOption?.filterControlOptions,
          onClick: onFilterControlClick,
        }
      : undefined
  )

  useControls(
    'Layer',
    controlsOption?.layerControlOptions
      ? {
          ...controlsOption?.layerControlOptions,
          onClick: onLayerControlClick,
        }
      : undefined
  )

  useControls(
    'Zoom',
    controlsOption?.zoomControlOptions
      ? controlsOption?.zoomControlOptions
      : undefined
  )

  const containerRef = React.useRef<HTMLDivElement | null>(null)

  const changeMapMode = React.useCallback(
    (mapMode: IMapLibreMapMode) => {
      if (mapMode === '2D') {
        if (map?.getLayer('3dTiles')) {
          map?.removeLayer('3dTiles')
        }
        map?.flyTo({
          bearing: 0,
          pitch: 0,
        })
        map?.dragRotate.disable()
      } else {
        map?.dragRotate.enable()
        map?.flyTo({
          pitch: 60,
        })
      }
    },
    [map]
  )

  React.useEffect(() => {
    changeMapMode(mapMode)
  }, [mapMode, changeMapMode])

  React.useEffect(() => {
    setMapType(mapType)
  }, [mapType])

  React.useEffect(() => {
    if (maxZoom > 24) {
      console.error('manZoom 不得大於 25')
    } else {
      map?.setMaxZoom(maxZoom)
    }
    map?.setMinZoom(minZoom)
  }, [mapMode, maxZoom, map, minZoom])

  const loadImage = (imageUrl: string, _map: Map) =>
    new Promise((resolve, reject) => {
      const img = new Image()

      img.onload = function (this: any) {
        const imgWidth = this.width as number
        const imgHeight = this.height as number

        const canvas: HTMLCanvasElement =
          (document.getElementById(canvasId) as HTMLCanvasElement) ||
          document.createElement('canvas')

        const canvasRatio = 5000 / imgWidth
        canvas.id = canvasId
        canvas.width = imgWidth * canvasRatio
        canvas.height = imgHeight * canvasRatio
        canvas.style.display = 'none'
        const context = canvas.getContext('2d')
        context?.drawImage(
          img,
          0,
          0,
          imgWidth * canvasRatio,
          imgHeight * canvasRatio
        )
        document.body.appendChild(canvas)

        let ratio = 1
        if (imgWidth > imgHeight) {
          ratio = 50 / imgWidth
        }
        if (imgHeight >= imgWidth) {
          ratio = 50 / imgHeight
        }
        let newWidth = imgWidth * ratio
        let newHeight = imgHeight * ratio
        const centerPoint = _map.project([0, 0])

        const topLeft = {
          x: centerPoint.x - newWidth / 2,
          y: centerPoint.y - newHeight / 2,
        }
        const bottomRight = {
          x: centerPoint.x + newWidth / 2,
          y: centerPoint.y + newHeight / 2,
        }
        setImageMapBounds([
          _map.unproject([topLeft.x, topLeft.y]),
          _map.unproject([bottomRight.x, bottomRight.y]),
        ])
        resolve({
          bounds: [
            _map.unproject([topLeft.x, topLeft.y]).toArray(),
            _map
              .unproject([
                centerPoint.x + newWidth / 2,
                centerPoint.y - newHeight / 2,
              ])
              .toArray(),
            _map.unproject([bottomRight.x, bottomRight.y]).toArray(),
            _map
              .unproject([
                centerPoint.x - newWidth / 2,
                centerPoint.y + newHeight / 2,
              ])
              .toArray(),
          ],
          maxBounds: [
            _map
              .unproject([
                centerPoint.x - (newWidth + 25) / 2,
                centerPoint.y + (newHeight + 25) / 2,
              ])
              .toArray(),
            _map
              .unproject([
                centerPoint.x + (newWidth + 25) / 2,
                centerPoint.y - (newHeight + 25) / 2,
              ])
              .toArray(),
          ],
          fitBounds: [
            _map
              .unproject([
                centerPoint.x - (newWidth + 10) / 2,
                centerPoint.y + (newHeight + 10) / 2,
              ])
              .toArray(),
            _map
              .unproject([
                centerPoint.x + (newWidth + 10) / 2,
                centerPoint.y - (newHeight + 10) / 2,
              ])
              .toArray(),
          ],
        })
      }
      img.onerror = function () {
        resolve(undefined)
      }
      img.crossOrigin = 'anonymous'
      img.src = imageUrl
    })

  React.useEffect(() => {
    let _center = convertToValidCenter(
      center ? center : (defaultCenter as LngLatLike)
    )

    const _map = new maplibregl.Map({
      container: containerId,
      center: _center,
      zoom: zoom ? zoom : defaultZoom,
      maxZoom: controlsOption?.maxZoom,
      minZoom: controlsOption?.minZoom,
      style: {
        version: 8,
        sources: {},
        layers: [],
      },
    })

    _map.on('load', async () => {
      if (mapType === MapType.GoogleMap) {
        // 標準版目前地圖都使用 Google 地圖，待需要使用非 google 地圖的地圖圖資時在實作
        // 電子地圖
        _map.addSource('twtx', {
          type: 'raster',
          tiles: [
            'https://wmts.nlsc.gov.tw/wmts/EMAP/default/GoogleMapsCompatible/{z}/{y}/{x}',
          ],
          tileSize: 256,
        })
        // 正射圖
        _map.addSource('twtxPhoto', {
          type: 'raster',
          tiles: [
            'https://wmts.nlsc.gov.tw/wmts/PHOTO2/default/GoogleMapsCompatible/{z}/{y}/{x}',
          ],
          tileSize: 256,
        })

        _map.addLayer({
          id: 'base-tiles',
          type: 'raster',
          source: 'twtx',
        })
      }
      if (mapType === MapType.Upload && layerFileUrl) {
        const bound: any = await loadImage(layerFileUrl, _map)
        if (!bound) {
          console.error('[mapLibre] load image bounds error')
          return
        }
        _map.addSource('upload_map_source', {
          type: 'canvas',
          canvas: canvasId,
          animate: false,
          coordinates: bound.bounds,
        })
        _map.addLayer({
          id: 'canvas-layer',
          type: 'raster',
          source: 'upload_map_source',
        })

        _map.fitBounds(bound.fitBounds, {
          animate: false,
        })
        _map.setMaxBounds(bound.maxBounds)
      }

      onLoad(_map)
      setMaplibreMap(_map)
    })
    _map.on('error', ev => {
      console.log('error', ev)
    })

    return () => {
      onLoad(null)
      _map.remove()
    }
  }, [mapType, layerFileUrl])

  return (
    <div
      css={css`
        position: relative;
        .maplibregl-ctrl-top-right {
          top: 48px;
          right: 40px;
        }
        .maplibregl-ctrl-bottom-right {
          bottom: 25px;
          right: 40px;
        }
        background-color: #ddd;
      `}
      style={{
        backgroundColor: '#fff',
        ...mapContainerStyle,
      }}
      ref={_ref => {
        containerRef.current = _ref
        if (typeof ref === 'function') {
          ref(_ref)
        } else if (ref) {
          ref.current = _ref
        }
      }}
      id={containerId}>
      {children}
    </div>
  )
})

export default MapLibreContainer

type IContrlHandler = IControlClass | IControl

function useControls(
  controlType: 'Filter' | 'Layer' | 'Zoom',
  options?: Record<string, any>
) {
  const { map } = useMapLibre()

  const [control, setControl] = React.useState<null | IContrlHandler>(null)

  React.useEffect(() => {
    if (!map) {
      return
    }
    if (!options) {
      return
    }

    const { position = 'top-right', ...props } = options

    const _control: IContrlHandler = cond([
      [equals('Filter'), always<IContrlHandler>(new FilterControl(props))],
      [equals('Layer'), always<IContrlHandler>(new LayerControl(props))],
      [
        equals('Zoom'),
        always<IContrlHandler>(
          new maplibregl.NavigationControl({
            showCompass: false,
          }) as any as IControl
        ),
      ],
    ])(controlType)

    setControl(_control)

    map.addControl(_control, position)
    return () => {
      map.hasControl(_control) && map.removeControl(_control)
      setControl(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  React.useEffect(() => {
    if (!control || !map) {
      return
    }
    if (!options) {
      map.hasControl(control as any) && map.removeControl(control as any)
      setControl(null)
      return
    }
    const { position: _, ...props } = options
    if ('updateProps' in control) {
      control.updateProps(props)
    }
  }, [control, map, options])
}

function convertToValidCenter(center: LngLatLike): LngLatLike {
  let result: LngLat
  try {
    result = maplibregl.LngLat.convert(center)
  } catch (error) {
    result = maplibregl.LngLat.convert([0, 0])
  }

  return result
}
