import { Select } from 'antd'
import { SelectProps as AntSelectProps, SelectValue } from 'antd/lib/select'
import React from 'react'
import styled from 'styled-components'

interface StyledSelectProps<T = SelectValue> extends AntSelectProps<T> {
  forwardRef?: React.Ref<any>
  ref?: any
}

export interface SelectProps<T = SelectValue> extends StyledSelectProps<T> {
  render?: (values: any) => any
  useForm?: boolean
}

const StyledSelect: any = styled(Select)`
  &.ant-select {
    & svg[data-icon='close-circle'] {
      fill: ${p => p.theme.select.clearIconFill};
    }
    & svg[data-icon='down'] {
      fill: ${p => p.theme.select.arrowDownIconFill};
    }
    &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background: ${p => p.theme.select.disabledBg};
    }
    .ant-select-selector {
      border-radius: 4px;
      background: ${p => p.theme.select.bg};
      color: ${p => p.theme.select.color};
      &:hover,
      &:active,
      &:focus {
        border-color: ${p => p.theme.select.hoverAndFocusBorderColor};
      }
      & .ant-select-selection-placeholder {
        color: ${p => p.theme.select.placeholderColor};
      }
    }

    .ant-select-arrow-icon {
      position: relative;
      top: -1px;
      font-size: 14px;
    }

    &.selection__multiple-items--hide
      .ant-select-selection--multiple
      .ant-select-selection__choice__disabled {
      display: none;
    }
  }
`

export default class SelectComponent<T = any> extends React.Component<
  StyledSelectProps<T>,
  any
> {
  public static Option = Select.Option

  public static OptionGroup = Select.OptGroup

  public static displayName = 'Select'

  render() {
    const { forwardRef, ...others } = this.props
    return (
      <StyledSelect ref={forwardRef} {...others}>
        {this.props.children}
      </StyledSelect>
    )
  }
}

export { SelectComponent as Select }
