import theme from '@theme'

export const tooltipPointFormat = (point, isLineChart, unit = '%') => {
  // console.log('[point]', point)
  return (
    `<div style="margin-top:4px; font-size:12px;font-weight:500;color:${theme.tooltip.color};">` +
    `${point.series.name} <span style="display:inline-block;">${point.y} ${unit}</span>` +
    `<span style="display:inline-block; background-color:${point.color}; ${
      point.colorIndex === 1 && isLineChart
        ? 'width:8px; height:12px;'
        : 'width:8px; height:12px;'
    }  margin-left:8px;"></span>` +
    '</div>'
  )
}

export const tooltipHeaderFormat = val => {
  const date = val.points.length > 0 ? val.points[0].key : ''

  return (
    `<b style="font-size:13px; color:${theme.tooltip.color};font-weight:500"><div style="margin-bottom:8px;">` +
    date +
    '</div></b>'
  )
}

export const initDualChart = {
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  colors: ['#ffcc2b', '#3bb0d4', '#db700f', '#88ce00', '#c13f3f', '#ac6a9f'],
  plotOptions: {
    column: {
      pointWidth: 10,
      state: { hover: { brightness: 0 } },
    },
    line: {
      marker: { radius: 4 },
    },
  },
  chart: {
    zoomType: 'xy',
  },
  title: {
    text: '',
  },
  xAxis: [
    {
      crosshair: true,
      labels: {
        style: {
          color: theme.tooltip.color,
          fontSize: '13px;',
        },
      },
    },
  ],
  yAxis: [
    {
      labels: {
        format: '{value}',
        style: {
          color: theme.tooltip.color,
          fontSize: '14px;',
        },
      },
      title: {
        enabled: false,
      },
      opposite: true,
    },
    {
      labels: {
        format: '{value}',
        style: {
          color: theme.tooltip.color,
          fontSize: '14px;',
        },
      },
      title: {
        margin: 40,
        style: {
          color: theme.tooltip.color,
        },
        useHTML: true,
      },
    },
  ],
  tooltip: {
    shared: true,
    useHTML: true,
    backgroundColor: theme.tooltip.bg,
    borderRadius: 5,
    borderColor: theme.tooltip.bg,
    formatter: function () {
      return this.points.reduce(function (s, point) {
        return s + tooltipPointFormat(point, true)
      }, tooltipHeaderFormat(this))
    },
  },
}

export const initColumnChart = {
  credits: {
    enabled: false,
  },
  colors: [theme.n.blue250, '#c0973f', theme.primary],
  chart: {
    type: 'column',
  },
  title: {
    text: '',
  },
  xAxis: {
    crosshair: true,
    labels: {
      style: {
        color: theme.tooltip.color,
        fontSize: '13px;',
      },
    },
  },
  yAxis: {
    labels: {
      style: {
        color: theme.tooltip.color,
        fontSize: '14px;',
      },
    },
    title: {
      margin: 40,
      style: {
        color: theme.tooltip.color,
      },
      useHTML: true,
    },
  },
  tooltip: {
    shared: true,
    useHTML: true,
    backgroundColor: theme.tooltip.bg,
    borderRadius: 4,
    formatter: function () {
      return this.points.reduce(function (s, point) {
        return s + tooltipPointFormat(point)
      }, tooltipHeaderFormat(this))
    },
  },
  plotOptions: {
    column: {
      pointWidth: 10,
      state: { hover: { brightness: 0 } },
      // pointPadding: 2,
      // borderWidth: 0,
    },
  },
}

export const initAlarmBarChart = {
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  colors: [theme.n.blue250, '#c0973f', theme.primary],
  chart: {
    type: 'column',
    height: 320,
    backgroundColor: 'transparent',
  },
  title: {
    text: '',
  },
  xAxis: {
    labels: {
      style: {
        color: theme.tooltip.color,
        fontSize: '13px;',
      },
    },
    title: {
      align: 'high',
      style: {
        color: '#4a4a4a',
        fontSize: 18,
      },
    },
  },
  yAxis: {
    labels: {
      style: {
        color: theme.tooltip.color,
        fontSize: '14px;',
      },
    },
    title: {
      margin: 0,
      style: {
        color: theme.tooltip.color,
      },
      useHTML: true,
      text: '',
    },
  },
  tooltip: {
    shared: true,
    useHTML: true,
    backgroundColor: theme.tooltip.bg,
    borderRadius: 4,
    formatter: function () {
      return this.points.reduce(function (s, point) {
        return s + tooltipPointFormat(point)
      }, tooltipHeaderFormat(this))
    },
  },
  plotOptions: {
    column: {
      pointWidth: 10,
      state: { hover: { brightness: 0 } },
      // pointPadding: 2,
      // borderWidth: 0,
    },
  },
}

export const initChart = {
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  colors: ['#b7d6e4', '#c0973f', '#1aadce'],
  plotOptions: {
    column: {
      pointWidth: 10,
      state: { hover: { brightness: 0 } },
    },
    line: {
      marker: { radius: 4 },
    },
  },
  chart: {
    zoomType: 'xy',
  },
  title: {
    text: '',
  },
  xAxis: [
    {
      crosshair: true,
      labels: {
        style: {
          color: theme.tooltip.color,
          fontSize: '13px;',
        },
      },
    },
  ],
  yAxis: [
    {
      labels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      opposite: true,
    },
    {
      labels: {
        format: '{value}',
        style: {
          color: theme.tooltip.color,
          fontSize: '14px;',
        },
      },
      title: {
        margin: 0,
        style: {
          color: theme.tooltip.color,
        },
        useHTML: true,
      },
    },
  ],
  tooltip: {
    shared: true,
    useHTML: true,
    backgroundColor: theme.tooltip.bg,
    borderRadius: 4,
    formatter: function () {
      return this.points.reduce(function (s, point) {
        return s + tooltipPointFormat(point, true)
      }, tooltipHeaderFormat(this))
    },
  },
}

export default initDualChart
