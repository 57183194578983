import { isNil } from '@shared/utils/webHelper'
import React from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { REPORT_TYPE_REQ_VAL } from './report.enum'

export const useReportQuery = <T>(
  url: string,
  querys: { [key: string]: any },
  options?: UseQueryOptions<T>
) => {
  const _querys = React.useMemo(() => {
    return isNil(querys)
      ? null
      : {
          ...querys,
          reportType: REPORT_TYPE_REQ_VAL[querys.reportType],
        }
  }, [querys])

  return useQuery([url, _querys], {
    enabled: !isNil(_querys),
    ...options,
  })
}
