import styled from 'styled-components'

type Props = {
  children?: any
  wrapperStyle?: React.CSSProperties
  style?: React.CSSProperties
  className?: string
  id?: string
}

export const breakPoints = {
  mobile: 768,
}

export const mediaQuerys = {
  mobile: '@media only screen and (max-width: 768px)',
  desktop: '@media only screen and (min-width: 768px)',
}

export function Layout({
  children,
  wrapperStyle,
  style,
  className,
  ...props
}: Props) {
  return (
    <LayoutWrapper style={wrapperStyle}>
      <Content className={className} style={style} {...props}>
        {children}
      </Content>
    </LayoutWrapper>
  )
}

const LayoutWrapper = styled.div`
  margin: ${p => p.theme.pageContent.margin};
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
  border-radius: ${p => p.theme.pageContent.borderRadius};
  min-height: calc(100vh - 64px);
  max-height: 100%;
  height: 100%;
  background-color: ${p => p.theme.pageContent.bg};
  display: flex;
`

const Content = styled.div`
  padding: 24px;
  flex: 1;
  ${mediaQuerys.mobile} {
    padding: 12px !important;
  }
`
export default Layout
