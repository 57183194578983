import { Form } from 'antd'
import styled from 'styled-components'

const StyledForm = styled(Form)`
  .ant-form-item .ant-form-item-label {
    padding: 0 0 4px;

    label {
      font-size: 12px;
      color: ${p => p.theme.darkGrey};
    }
  }
`

export default StyledForm
