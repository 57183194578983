import { Device } from '@services/device'
import { ISession } from '@services/session'
import { isNil } from 'ramda'
import { DEFAULT_MAP_PLATFORM } from '../map.options'
import { IInnmaxMapInstance, IMapPlatform, MapType } from '../map.types'

interface EventMap {
  mapPlatformChange: (platform: IMapPlatform) => void
  mapInstanceChange: <K extends keyof IInnmaxMapInstance>(
    property: K,
    value: IInnmaxMapInstance[K]
  ) => void
}

export class InnmaxMapTool {
  public static readonly DEFAULT_ZOOM = 10
  private static mapPlatform: IMapPlatform = DEFAULT_MAP_PLATFORM
  private static _eventListeners: {
    [K in keyof EventMap]?: EventMap[K][]
  } = {}

  private static getEventListeners<K extends keyof EventMap>(
    eventName: K
  ): EventMap[K][] {
    return this._eventListeners[eventName] || []
  }

  static setMapPlatform(platform: IMapPlatform) {
    this.mapPlatform = platform
    this.getEventListeners('mapPlatformChange').forEach(cb => cb(platform))
  }

  static emitMapInstanceChange<K extends keyof IInnmaxMapInstance>(
    property: K,
    value: IInnmaxMapInstance[K]
  ) {
    this.getEventListeners('mapInstanceChange').forEach(cb =>
      cb(property, value)
    )
  }

  static getMapPlatform() {
    return this.mapPlatform
  }

  static addEventListener<K extends keyof EventMap>(
    eventName: K,
    callback: EventMap[K]
  ) {
    if (!this._eventListeners[eventName]) {
      this._eventListeners[eventName] = []
    }
    this._eventListeners[eventName].push(callback)
  }

  static removeEventListener<K extends keyof EventMap>(
    eventName: K,
    callback: EventMap[K]
  ) {
    if (!this._eventListeners[eventName]) {
      return
    }
    this._eventListeners[eventName] = this._eventListeners[eventName].filter(
      cb => cb !== callback
    ) as any
  }

  static isLatLon(position: Device) {
    if (
      'lat' in position &&
      'lon' in position &&
      !isNil(position.lat) &&
      !isNil(position.lon)
    ) {
      return true
    }
    return false
  }

  static isXY(position: Device) {
    if (
      'x' in position &&
      'y' in position &&
      !isNil(position.x) &&
      !isNil(position.y)
    ) {
      return true
    }
    return false
  }

  static isDevicePositioned(session: ISession, device: Device) {
    if (session?.type === MapType.GoogleMap) {
      return this.isLatLon(device)
    }
    if (session?.type === MapType.Upload) {
      return this.isXY(device)
    }
    return false
  }
}
