import React from 'react'
import GoogleMap from '../Google'
import MapLibre from '../MapLibre'
import { useInnmaxMap } from '../Provider'
import { IDeviceMarkerProps, ISessionMarkerProps, MapType } from '../map.types'

const InnmaxSessionMarker = (props: ISessionMarkerProps) => {
  const { mapPlatform, mapType } = useInnmaxMap()

  if (mapType === MapType.Upload || mapPlatform === 'MapLibre') {
    return <MapLibre.SessionMarker baseZIndex={1} {...props} />
  }
  return <GoogleMap.SessionMarker {...props} />
}

const InnmaxDeviceMarker = (props: IDeviceMarkerProps) => {
  const { mapPlatform, mapType, focusDevice } = useInnmaxMap()

  const isFocus = React.useMemo(() => {
    return (
      focusDevice?.id === props.item?.id &&
      focusDevice?.deviceTypeId === props.item?.deviceTypeId
    )
  }, [focusDevice, props.item])

  if (mapType === MapType.Upload || mapPlatform === 'MapLibre') {
    return <MapLibre.DeviceMarker baseZIndex={99} focus={isFocus} {...props} />
  }
  return <GoogleMap.DeviceMarker focus={isFocus} {...props} />
}

type InnternalInnmaxMarker = {
  Session: typeof InnmaxSessionMarker
  DeviceMarker: typeof InnmaxDeviceMarker
}

export const InnmaxMarker: InnternalInnmaxMarker = {
  Session: InnmaxSessionMarker,
  DeviceMarker: InnmaxDeviceMarker,
}
