import { Clusterer } from '@react-google-maps/marker-clusterer'
import { Device } from '@services/device'
import { ISession } from '@services/session'
import { ControlPosition, LngLatLike, Map } from 'maplibre-gl'
import { IMapLibreContext } from './MapLibre/Provider'

export type IInnmaxMapInstance = {
  selectedDevices: Device[]
  setSelectedDevices: (devices: Device[]) => void
  focusDevice?: Device
  setFocusDevice: (device: Device | undefined) => void
  proxyHandler: ProxyHandler<any>
  /**
   * 當地圖類型為圖資時可用
   */
  projectToRatioPoints?: IMapLibreContext['projectToRatioPoints']
  map: google.maps.Map | Map | null
}

export type InnmaxMap2Props = React.PropsWithChildren<{
  id?: string
  mapContainerStyle?: React.CSSProperties
  googleMapOptions?: {
    controlsOption?: IGoogleMapControlsOption
  }
  mapLibreOptions?: {
    controlsOption?: IMapLibreControlsOption
  }
  mapType?: MapType
  layerFileUrl?: string
  mapInstance?: IInnmaxMapInstance
  onRectangleComplete?: (rectangle: google.maps.Rectangle) => void
  onRectangleClick?: (e: google.maps.MapMouseEvent) => void
  onFilterControlClick?: () => void
  onLayerControlClick?: () => void
  onStreetViewChanged?: (isStreetView: boolean) => void
}>

export interface IGoogleMapControlsOption extends google.maps.MapOptions {
  zoomControl?: null | boolean
  ZoomControlOptions?: null | {
    position?: google.maps.ControlPosition
    styles?: React.CSSProperties
  }
  backRectangleSelectControl?: boolean
  backRectangleSelectControlOptions?: {
    position?: google.maps.ControlPosition
    styles?: React.CSSProperties
    enableRectangleSelect?: boolean
  }
  placeSearchControl?: boolean
  placeSearchControlOptions?: {
    position?: google.maps.ControlPosition
    styles?: React.CSSProperties
  }
  filterControl?: boolean
  filterControlOptions?: {
    active: boolean
    position?: google.maps.ControlPosition
    styles?: React.CSSProperties
  }
  layerControl?: boolean
  layerControlOptions?: {
    position?: google.maps.ControlPosition
    styles?: React.CSSProperties
    iconColor?: string
  }
  satelliteControl?: boolean
  satelliteControlOption?: {
    position?: google.maps.ControlPosition
    styles?: React.CSSProperties
  }
}

export interface IMapLibreControlsOption {
  zoomControlOptions?: {
    position?: ControlPosition
  }
  filterControlOptions?: {
    active: boolean
    position?: ControlPosition
    styles?: React.CSSProperties
  }
  layerControlOptions?: {
    position?: ControlPosition
    styles?: React.CSSProperties
  }
  minZoom?: number
  maxZoom?: number
}

export interface IGoogleMapProps extends InnmaxMap2Props {
  onRectangleComplete?: (rectangle: google.maps.Rectangle) => void
  onRectangleClick?: (e: google.maps.MapMouseEvent) => void
  onFilterControlClick?: () => void
  onLayerControlClick?: () => void
  controlsOption?: IGoogleMapControlsOption
  onStreetViewChanged?: (isStreetView: boolean) => void
}

export type IMapLibreContainerProps = {
  containerId?: string
  center?: LngLatLike
  zoom?: number
  mapContainerStyle?: React.CSSProperties
  maxZoom?: number
  minZoom?: number
  mapType?: MapType
  layerFileUrl?: string
  controlsOption?: IMapLibreControlsOption
  onFilterControlClick?: () => void
  onLayerControlClick?: () => void
}

export enum MapType {
  UNKNOWN = 0, // 未知
  TREE = 1, // 節點
  GoogleMap = 2, // 地圖
  Upload = 3, // 圖資
}

export type IMapPlatform = 'Google' | 'MapLibre'

export type IMapMarkerPosition =
  | { lat: number | null | undefined; lon: number | null | undefined }
  | { x: number | null | undefined; y: number | null | undefined }

export type IMarkerProps = {
  focus?: boolean
  tooltipContent?: React.ReactNode
  draggable?: boolean
  deletable?: boolean
  selected?: boolean
  children?: React.ReactNode
  tooltip?: boolean
  onLeftClick?: () => void
  onRightClick?: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  onDelete?: () => void
  onDrag?: () => void
  onDragEnd?: (markerPosition: IMapMarkerPosition) => void
  defaultPosition?: IMapMarkerPosition
  baseZIndex?: number
}

export interface ISessionMarkerProps
  extends Omit<
    IMarkerProps,
    'onDelete' | 'onDragEnd' | 'onLeftClick' | 'defaultPosition'
  > {
  session: ISession & {
    lng?: number
  }
  deviceCountInfo?: {
    deviceCount: number
    deviceAlarmCount: number
    deviceRepairCount: number
    deviceNormalCount: number
  }
  onDelete?: (d: ISession) => void
  onDragEnd?: (d: ISession) => void
  onLeftClick?: (d: ISession) => void
  size?: 'small' | 'large'
}

export interface IDeviceMarkerProps
  extends Omit<IMarkerProps, 'onDelete' | 'onDragEnd' | 'onLeftClick'> {
  item?: Device
  onDelete?: (d: Device) => void
  onDragEnd?: (d: Device) => void
  onLeftClick?: (d: Device) => void
  clusterer?: Clusterer
}

export type IInnmaxClusterProps = {
  devices: Device[]
  onLeftClick?: (d: Device) => void
}
