const primary = '#0074A8'
const primary100 = 'rgba(0, 116, 168, 0.1)'
const secondary = '#e6edf1'
const info = '#5E9CE5'
const darkGrey = '#4a4a4a'
const darkGrey500 = 'rgba(74, 74, 74, 0.5)'
const whisper = '#e4e4e4'
const grey = '#878787'
const lightBlue = '#d1e0e7'
const silverGrey = '#c0c0c0'
const light = '#fff'
const beige = '#fff1b8'
const inputColor = 'rgba(0, 0, 0, 0.85)'
const gray88 = '#e0e0e0'

/**
 * @return { [key: string]: any}
 */
const theme = {
  blue100: '#e7f2fd',
  blue150: 'rgba(183, 220, 255, 0.15)',
  blue200: 'rgba(183, 220, 255, 0.2)',
  blue300: 'rgba(183, 220, 255, 0.3)', // b7dcff
  blue600: 'rgba(183, 220, 255, 0.6)',
  blue700: '#b7dcff',
  cyan: '#0ab1c1',
  blue: '#5E9CE5',
  danger: '#d74c4c',
  dark: '#222',
  dark100: 'rgba(0, 0, 0, 0.1)',
  dark300: 'rgba(0, 0, 0, 0.3)',
  dark500: 'rgba(0, 0, 0, 0.5)',
  darkGrey: darkGrey,
  grey,
  grey700: 'rgba(74, 74, 74, 0.7)',
  greyF9: '#f9f9f9',
  silverGrey,
  info: info, // (16, 94, 166)
  info200: 'rgba(16, 94, 166, 0.2)',
  info500: 'rgba(16, 94, 166, 0.5)',
  info900: '#4098ef',
  lightDark: '#333',
  lightGrey: '#d8d8d8',
  lightPink: '#ffeaea',
  light: light,
  primary: primary,
  red100: '#ffdad6',
  red200: '#e17567',
  red500: 'rgba(243, 76, 57, 0.5)',
  red: 'rgb(243, 76, 57)',
  crimson: '#ce2512',
  secondary,
  warning: primary,
  yellow200: '#ffcc2b',
  yellow100: beige,
  yellow: '#ffc247',
  btn: {
    text: '#fff',
    bg: primary,
    bgHover: '#005378',
    ghostColor: primary,
    ghostHover: 'rgba(0, 116, 168, 0.1)',
    linkHover: '#0085c1',
    linkColor: '#5E9CE5',
    info: info,
    infoHover: 'rgba(94, 156, 229, 0.5)',
    danger: '#e17567',
    dangerHover: 'rgb(255, 246, 228)',
    warning: '#b68116',
    warningHover: '#9d6f12',
  },
  logoText: '#222',
  gray88,
  whisper: whisper,
  reporttable: {
    headBg: grey,
    head: '#fff',
    bodyColor: '#878787',
    bodyOddBg: '#fff',
    bodyEvenBg: '#f2f6f8',
    hoverBg: '#fff5d5',
    hoverFirstColumnBg: '#9f9575',
  },
  slider: '#c0973f',
  sliderBg: '#c0973f',
  block: {
    text: grey,
    section: darkGrey,
    title: darkGrey,
    content: '#f4f3f3',
    borderBottom: info,
    borderRight: 'transparent',
  },
  sidemenu: primary,
  calendarIcon: grey,
  searchIcon: primary,
  schedule: {
    searchBtn: darkGrey,
    searchBtnBg: primary,
    searchBtnIcon: darkGrey,
    text: info,
    checkboxDisable: darkGrey,
  },
  font: {
    primary,
    secondary: grey,
  },
  icon: {
    primary: darkGrey500,
    primaryHover: darkGrey,
    secondary: '#fff',
  },
  lightBlue,
  darkGrey500,
  map: {
    rectangleOptions: {
      fillColor: 'rgba(255, 207, 110, 0.3)',
      strokeColor: '#b68116',
      strokeWeight: 2,
    },
  },
  tooltip: {
    color: darkGrey,
    bg: beige,
  },
  message: {
    color: darkGrey,
    bg: beige,
    fontSize: '16px',
    fontWeight: 500,
  },
  gamboge: '#b68116',
  menuInlineTooltip: {
    bg: '#fff',
    color: grey,
    active: primary,
  },
  group: {
    active: { bg: '#dff5ff' },
  },
  infowindow: {
    color: darkGrey,
    border: primary,
    borderRadius: '4px',
    titleBg: primary,
    titleColor: light,
    columnBg: '#cce4ee',
    width: '143px',
    height: '136px',
  },
  treeview: {
    icon: darkGrey500,
    iconHover: darkGrey,
    mapIcon: '#7fbad4',
    color: darkGrey,
  },

  imageuploader: {
    bg: '#e6edf1',
    boderColor: 'transparent',
    iconColor: darkGrey,
  },
  table: {
    head: {
      color: 'grey',
      bg: 'transparent',
      separatorColor: grey,
    },
    cell: {
      color: darkGrey,
      oddBg: '#fff',
      evenBg: '#fff',
      bgHover: '#fffcf1',
      borderBottom: `1px solid ${whisper}`,
      selectedBg: beige,
    },
    borderSpacing: '0px 0px',
  },
  // Dashboard Table
  colorfulTable: {
    head: {
      color: '#0074a8',
      bg: '#dff5ff',
    },
    cell: {
      evenBg: '#e6edf173',
      oddBg: '#fff',
      color: '#000000d9',
      bg: '#fff',
      hoverBg: 'rgba(230, 237, 241, 0.9)',
      highlightColor: '#000000d9',
    },
  },
  pagination: {
    borderColor: primary,
    color: primary,
    bg: primary,
    hover: {
      borderColor: primary,
      color: '#fff',
      bg: primary,
    },
  },
  checkbox: {
    border: '#c0c0c0',
    bg: '#dff5ff',
    text: darkGrey,
    indeterminate: primary,
    checkedColor: darkGrey,
  },
  input: {
    bg: '#fff',
    disabledBg: '#f5f5f5',
    disabledColor: 'rgba(0, 0, 0, 0.25)',
    placeholderColor: silverGrey,
    color: inputColor,
    borderColor: gray88,
    hoverAndFocusBorderColor: primary,
    clearIconFill: 'currentColor',
  },
  datePicker: {
    bg: '#fff',
    disabledBg: '#f5f5f5',
    disabledColor: 'rgba(0, 0, 0, 0.25)',
    placeholderColor: silverGrey,
    color: inputColor,
    borderColor: gray88,
    hoverAndFocusBorderColor: primary,
    clearIconFill: 'currentColor',
    calendarIconFill: 'currentColor',
    rangeActiveBarBg: '#1890ff',
    panel: {
      bg: '#fff',
      borderColor: '#f0f0f0',
      color: 'rgba(0, 0, 0, 0.85)',
      unselectColor: '#c0c0c0',
      todayColor: '#1890ff',
      selectedBg: '#1890ff',
      selectedColor: '#fff',
      hoverBg: '#f5f5f5',
      hoverColor: 'rgba(0, 0, 0, 0.85)',
      prevAndNextBtnColor: 'rgba(0, 0, 0, 0.25)',
      prevAndNextBtnHoverColor: 'rgba(0, 0, 0, 0.85)',
      inRangeBg: '#e6f7ff',
      inRangeColor: 'rgba(0, 0, 0, 0.85)',
      inRangeBorderColor: '#7ec1ff',
      inRangeHoverBg: '#cbe6ff',
    },
  },
  select: {
    bg: '#fff',
    disabledBg: '#f5f5f5',
    disabledColor: 'rgba(0, 0, 0, 0.25)',
    placeholderColor: silverGrey,
    color: inputColor,
    borderColor: gray88,
    hoverAndFocusBorderColor: primary,
    clearIconFill: 'currentColor',
    arrowDownIconFill: 'currentColor',
    dropdown: {
      bg: '#fff',
      color: inputColor,
      hoverBg: primary100,
      hoverColor: primary,
      selectedBg: primary100,
      selectedColor: primary,
      item: {
        bg: '#fff',
        color: inputColor,
        fontWeight: 'normal',
        hoverBg: '#f5f5f5',
        hoverColor: inputColor,
        selectedFontWeight: '600',
        selectedBg: '#e6f7ff',
        selectedColor: inputColor,
      },
    },
  },
  dropdown: {
    bg: '#fff',
    color: darkGrey,
    hoverBg: info,
    hoverColor: '#fff',
  },
  layout: {
    bg: 'transparent',
    borderTop: primary,
    content: {
      bg: secondary,
    },
  },
  menu: {
    bg: 'transparent',
    color: grey,
    hoverColor: primary,
    hoverBg: lightBlue,
    activeBg: lightBlue,
    activeColor: primary,
    sub: {
      bg: '#fafafa',
    },
  },
  pageContent: {
    bg: '#fff',
    borderRadius: '8px 0 0 0',
    margin: '0 0 0 24px',
  },
  form: {
    evenRowBg: '#fff',
    oddRowBg: '#f4f3f3',
    labelColor: darkGrey,
    valueColor: darkGrey,
    border: '0px',
  },
  tabs: {
    color: '#c0c0c0',
    hoverColor: primary,
    activeColor: primary,
    border: `1px solid #c0c0c0`,
  },
  modal: {
    bg: '#fff',
    mask: 'rgba(0, 44, 86, 0.7)',
    maskDarken: 'rgba(0, 44, 86, 0.9)',
    border: info,
    color: primary,
    footerBorderTop: '1px solid #e4e4e4',
  },
  widget: {
    header: {
      bg: '#fff',
      color: darkGrey,
      borderBottom: `1px solid rgba(0, 0, 0, 0.1)`,
    },
    content: {
      bg: '#fff',
      titleColor: darkGrey,
      titleDescColor: '#a1ddff',
    },
  },
  breadcrumb: {
    focusColor: primary,
    color: grey,
  },
  tree: {
    childrenTreeBg: '#f8f8f8',
    childrenTreeBoxShadow:
      'inset 0px 7px 6px -6px rgba(0, 0, 0, 0.1), inset 0px -7px 6px -6px rgba(0, 0, 0, 0.1)',
    rootColor: primary,
    normalColor: darkGrey,
    activeColor: light,
    activeBg: '#5e9ce5',
    hoverBg: 'rgba(255, 205, 41, 0.2)',
    rootHoverColor: primary,
    normalHoverColor: darkGrey,
    borderLeft: `3px solid ${primary}`,
    borderBottomColor: '#e4e4e4',
  },
  statusTag: {
    alarmBg: '#e17567',
    repairBg: '#767575',
    failBg: '#767575',
    normalBg: '#84cf35',
  },
  report: {
    color: darkGrey,
    tooltip: {
      bg: beige,
      borderColor: undefined,
    },
    gridLineColor: '#e6e6e6',
    xAxisLineColor: '#e6e6e6',
  },
  text: {
    primary,
    normal: darkGrey,
    info: '#b68116',
  },

  n: {
    blue100: '#e6edf1',
    blue150: '#dff5ff',
    blue250: '#b7d6e4',
    blue400: '#5e9ce5',
    blue500: '#0074a8',
    blue600: '#0e2e3c',
    lightBlue400: '#00c5ff',
    gray50: '#f8f8f8',
    gray100: '#e4e4e4',
    gray200: '#c0c0c0',
    gray250: '#767575',
    gray300: '#878787',
    geay350: '#96a6ad',
    gray400: '#4a4a4a',
    gray500: '#313131',
    yellow50: '#fffcf1',
    yellow100: '#fff1b8',
    yellow400: '#ffcc2b',
    green300: '#6cc26c',
    green400: '#60bb00',
    green500: '#00b900',
    pink400: '#e17567',
    orange400: '#ec9947',
    purple600: '#771c70',
    success: '#6dd400',
    danger: '#ff342b',
    warning: '#ff9408',
    secondary: '#b7b7b7',
    map: {
      rectangleOptions: {
        fillColor: 'rgba(255, 204, 43, 0.2)',
        strokeColor: '#ffcc2b',
        strokeWeight: 4,
      },
    },
  },
}

module.exports = theme
