import styled from 'styled-components'
import QueryTable from './QueryTable'

function ReportTable(props: any) {
  return (
    <StyledTable bordered={true} {...props}>
      {props.children}
    </StyledTable>
  )
}
const StyledTable = styled(QueryTable)`
  .ant-table-container {
    border: 0px !important;
  }
  table {
    border-collapse: separate;
    border-spacing: 3px !important;
    border: 0px !important;
  }
  .ant-table-thead {
    line-height: 1em;

    > tr {
      background: transparent;
    }

    > tr > th {
      font-size: 14px;
      font-weight: 500;
      padding: 11px 12px;
      color: ${p => p.theme.reporttable.head};
      background: ${p => p.theme.reporttable.headBg};
      border: 0px !important;
      :first-child {
        font-size: 16px;
      }
    }
  }

  .ant-table-tbody,
  .ant-table-summary {
    > tr > td {
      height: 37px;
      color: ${p => p.theme.reporttable.bodyColor} !important;
      border: 0px !important;
    }

    > tr.ant-table-placeholder > td:first-child {
      background: #fff !important;
    }

    > tr > td:first-child {
      font-size: 14px;
      color: ${p => p.theme.reporttable.head} !important;
      background: ${p => p.theme.reporttable.headBg} !important;
    }

    > tr:nth-of-type(odd) > td {
      background: ${p => p.theme.reporttable.bodyOddBg};
    }

    > tr:nth-of-type(even) > td {
      background: ${p => p.theme.reporttable.bodyEvenBg};
    }

    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      :first-child {
        background: ${p => p.theme.reporttable.hoverFirstColumnBg} !important;
      }
      background: ${p => p.theme.reporttable.hoverBg};
    }
  }
`
export default ReportTable
