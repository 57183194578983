import theme from '@theme'

export const tooltipPointFormat = (
  point: any,
  isLineChart: any,
  unit = '%'
) => {
  return (
    `<div style="margin-top:4px; font-size:12px;font-weight:500;color:${theme.tooltip.color};">` +
    `${point.series.name} <span style="display:inline-block;">${point.y} ${unit}</span>` +
    `<span style="display:inline-block; background-color:${point.color}; ${
      point.colorIndex === 1 && isLineChart
        ? 'width:8px; height:12px;'
        : 'width:8px; height:12px;'
    }  margin-left:8px;"></span>` +
    '</div>'
  )
}

export const tooltipHeaderFormat = (val: any) => {
  const date = val.points.length > 0 ? val.points[0].key : ''

  return (
    `<b style="font-size:13px; color:${theme.tooltip.color};font-weight:500"><div style="margin-bottom:8px;">` +
    date +
    '</div></b>'
  )
}

export const initChart = {
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  colors: ['#b7d6e4', '#c0973f', '#1aadce'],
  plotOptions: {
    column: {
      pointWidth: 10,
      state: { hover: { brightness: 0 } },
    },
    line: {
      marker: { radius: 4 },
    },
  },
  chart: {
    zoomType: 'xy',
  },
  title: {
    text: '',
  },
  xAxis: [
    {
      crosshair: true,
      labels: {
        style: {
          color: theme.tooltip.color,
          fontSize: '13px;',
        },
      },
      title: {
        align: 'high',
        style: {
          color: '#4a4a4a',
          fontSize: 18,
        },
      },
    },
  ],
  yAxis: [
    {
      labels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      opposite: true,
    },
    {
      labels: {
        format: '{value}',
        style: {
          color: theme.tooltip.color,
          fontSize: '14px;',
        },
      },
      title: {
        margin: 0,
        style: {
          color: theme.tooltip.color,
        },
        useHTML: true,
      },
    },
  ],
  tooltip: {
    shared: true,
    useHTML: true,
    backgroundColor: theme.tooltip.bg,
    borderRadius: 4,
    formatter: function () {
      const _this = this as any

      return _this.points.reduce(function (s: any, point: any) {
        return (
          s + tooltipPointFormat(point, true, point.series.userOptions.unit)
        )
      }, tooltipHeaderFormat(this))
    },
  },
}
