import { QueryVariables } from '@shared/innmaxLib/services'
import { formatDate, isNil } from '@shared/utils/webHelper'
import { pathOr } from 'ramda'
import React from 'react'

import {
  IQueryProps,
  PAGE_SIZE,
  TYPE,
  getLinkRateAverageData,
  useReportQuery,
  xAxisName,
} from '@services/report'

export const useLinkRateChart = (
  querys: { [key: string]: any },
  options?: QueryVariables
) => {
  const { data, ...others } = useReportQuery(
    '/device/report/dailyLinkRateByDeviceTypes',
    { ...querys, pageNum: undefined, pageSize: undefined },
    {
      enabled: !isNil(querys),
      ...options,
    }
  )

  const dataSource = React.useMemo(() => {
    if (isNil(data)) {
      return { totalPages: 0, total: 0, content: [] }
    }

    const { reportType } = querys

    const contentData = (pathOr([], ['data'], data) as any[]).map(i => {
      let displayDate: any = ''
      switch (reportType) {
        case TYPE.YEAR:
          const m = new Date(i.date).getMonth()
          displayDate = xAxisName(m)
          break
        case TYPE.MONTH:
          displayDate = `${new Date(i.date).getDate()}日`
          break
        case TYPE.DAY:
          displayDate = formatDate(
            new Date(`${i.date}:00:00`).valueOf(),
            'YYYY.MM.DD HH'
          )
          break
        default:
          break
      }

      return { ...i, displayDate }
    })

    const currentTime = new Date().getTime()

    const sourceDate = (date: any) => {
      switch (reportType) {
        case TYPE.YEAR:
        case TYPE.MONTH:
          return new Date(date)
        case TYPE.DAY:
          return new Date(`${date}:00:00`)
        default:
          return new Date()
      }
    }

    const total = contentData.length
    const xAxis = contentData.map(i => Math.min(Math.round(i.rate * 100), 100))

    const yAxis = getLinkRateAverageData(
      xAxis.filter(
        (val, index) =>
          currentTime > sourceDate(contentData[index].date).getTime()
      )
    )

    const content = contentData.map((i, idx: number) => ({
      ...i,
      rate: Math.min(Math.round(i.rate * 100), 100),
      avgRate: yAxis[idx],
    }))

    return {
      xAxis,
      yAxis,
      xAxisLabels: contentData.map(i => i.date),
      total,
      totalPages: Math.ceil(total / PAGE_SIZE),
      content,
    }
  }, [data, querys]) //eslint-disable-line

  return {
    dataSource,
    ...others,
  }
}

export const useExportLinkRateRpt = (
  query?: IQueryProps,
  options?: QueryVariables
) => {
  const [enabled, setIsEnabled] = React.useState(false)

  const { isLoading } = useReportQuery(
    '/device/report/dailyLinkRateByDeviceTypes/export',
    { deep: true, ...query, pageNum: undefined, pageSize: undefined },
    {
      ...options,
      enabled,
      onSuccess: (response: any) => {
        if (options?.onSuccess) {
          options.onSuccess(response)
        }
        setIsEnabled(false)
      },
      onError: error => {
        console.log(error)
        setIsEnabled(false)
      },
    }
  )

  return [
    isLoading,
    () => {
      setIsEnabled(true)
    },
  ] as [boolean, () => void]
}
