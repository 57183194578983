import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { createPortal } from 'react-dom'
import { useGoogleMap } from '@react-google-maps/api'
import ZoomInIcon from './ZoomInIcon'
import ZoomOutIcon from './ZoomOutIcon'

// Currently supported only for GoogleMap

export type ZoomControlProps = {
  position: google.maps.ControlPosition
  styles?: React.CSSProperties
}

function ZoomControl({
  position = window.google.maps.ControlPosition.LEFT_BOTTOM,
  styles = {},
}: ZoomControlProps) {
  const { t } = useTranslation()

  const map: google.maps.Map | null = useGoogleMap()

  const [container] = React.useState(document.createElement('div'))

  React.useEffect(() => {
    const controlsContainer: any = map?.controls[position]
    if (controlsContainer) {
      controlsContainer.push(container)
      return () => {
        const index = controlsContainer.indexOf(container)
        if (index !== -1) {
          controlsContainer.removeAt(index)
        }
      }
    }
  }, [map]) // eslint-disable-line

  React.useEffect(() => {
    for (const [key, value] of Object.entries(styles as any)) {
      ;(container.style as any)[key] = value
    }
  }, [styles]) // eslint-disable-line

  return createPortal(
    <StyledControl>
      <button
        title={t('zoom in')}
        onClick={() => map && map.setZoom((map.getZoom() || 0) + 1)}>
        <ZoomInIcon />
      </button>
      <StyledLine />
      <button
        title={t('zoom out')}
        onClick={() => map && map.setZoom((map.getZoom() || 0) - 1)}>
        <ZoomOutIcon />
      </button>
    </StyledControl>,
    container
  )
}

const StyledControl = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 4px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  width: 28px;
  overflow: hidden;

  button:hover {
    background-color: #d1e0e7;
  }
`

const StyledLine = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0;
  height: 1px;
  background-color: rgb(230, 230, 230);
  top: 0px;
`

export default React.memo(ZoomControl)
