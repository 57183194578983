import {
  Marker as MarkerComponent,
  InfoWindow as MarkerInfoWindow,
  OverlayView,
  OverlayViewF,
  OverlayViewProps,
} from '@react-google-maps/api'
import { Clusterer } from '@react-google-maps/marker-clusterer'
import cx from 'classnames'
import React from 'react'
import styled from 'styled-components'

import { DeviceStatus } from '@services/constants'
import { Device } from '@services/device'
import useVisible from '@shared/innmaxLib/hooks/useVisible'
import GoogleMap from '@shared/innmaxMap/map/GoogleMap'
import { markerIcon } from '../../components/Marker'
import { DeleteWindow } from '../../components/Window'

interface MarkerComponentProps
  extends Omit<OverlayViewProps, 'position' | 'mapPaneName'> {
  item: Device
  focus?: boolean
  tooltip?: boolean
  enableClusterer?: boolean
  deletable?: boolean
  draggable?: boolean
  selected?: boolean
  showLabel?: boolean
  clusterer?: Clusterer
  onLeftClick?: (d: Device) => void
  onRightClick?: (d: Device) => void
  onMouseOver?: (d: Device) => void
  onMouseOut?: (d: Device) => void
  onDrag?: (e: google.maps.MapMouseEvent) => void
  onDragEnd?: (e: Device) => void
  onDelete?: (d: Device) => void
}

export default function Marker({
  children,
  item,
  focus,
  tooltip = true,
  clusterer,
  enableClusterer = false,
  deletable = false,
  draggable = false,
  selected = false,
  showLabel = false,
  onLeftClick,
  onRightClick,
  onMouseOver,
  onMouseOut,
  onDrag,
  onDragEnd,
  onDelete,
  ...others
}: MarkerComponentProps) {
  const markerRef = React.useRef<any>()
  const markerIconRef = React.useRef<any>()
  const labelRef = React.useRef(null)

  const [labelLineRect, setLabelLineRect] = React.useState({
    width: 0,
    height: 0,
  })

  const { isStreetMode } = GoogleMap.useGoogleMap()

  const [markerPosition, setMarkerPosition] = React.useState<
    google.maps.LatLng | google.maps.LatLngLiteral
  >()

  const tooltipWindow = useVisible()

  const deleteWindow = useVisible()

  const markerVisible = useVisible()

  const icon = item?.deviceType && markerIcon(item)

  const className = React.useMemo(
    () =>
      cx(
        item?.deviceType,
        item?.deviceStatus === DeviceStatus.ALARM
          ? 'alarm'
          : item?.deviceStatus === DeviceStatus.REPAIR
          ? 'repair'
          : 'normal',
        item?.subDeviceCount
          ? item?.isSubAlarm
            ? 'has-child-alarm'
            : 'has-child'
          : '',
        item?.brightnessType,
        { selected: selected },
        { 'icon-animation': focus },
        { hidden: enableClusterer && !markerVisible.visible },
        'marker-wrapper',
        { scale: showLabel }
      ),
    [item, selected, focus, enableClusterer, markerVisible.visible]
  ) //eslint-disable-line

  const handleLeftClick = React.useCallback(
    (e: any) => {
      item && onLeftClick && onLeftClick(item)
    },
    [onLeftClick]
  ) //eslint-disable-line

  const handleRightClick = React.useCallback(
    (e: any) => {
      item && onRightClick && onRightClick(item)

      if (deletable) {
        deleteWindow.toggle()
      }
    },
    [onRightClick]
  ) //eslint-disable-line

  const handleMouseOver = React.useCallback((e: any) => {
    tooltipWindow.open()
  }, []) //eslint-disable-line

  const handleMouseOut = React.useCallback((e: any) => {
    tooltipWindow.close()
  }, []) //eslint-disable-line

  const handleOnDrag = React.useCallback(
    (e: google.maps.MapMouseEvent) => {
      setMarkerPosition(e.latLng?.toJSON())
      onDrag && onDrag(e)
    },
    [onDrag]
  ) //eslint-disable-line

  const handleOnDragEnd = React.useCallback(
    (e: google.maps.MapMouseEvent) => {
      setMarkerPosition(e.latLng?.toJSON())
      onDragEnd &&
        onDragEnd({
          ...item,
          lat: e?.latLng?.lat() || 0,
          lon: e?.latLng?.lng() || 0,
        })
    },
    [onDragEnd]
  ) //eslint-disable-line

  const handleOnMarkerVisibleChanged = React.useCallback(() => {
    if (markerRef) {
      markerRef?.current?.marker?.visible
        ? markerVisible.open()
        : markerVisible.close()
    }
    tooltipWindow.close()
  }, [markerRef]) //eslint-disable-line

  React.useEffect(() => {
    setMarkerPosition({
      lat: item?.lat,
      lng: item?.lon,
    })
  }, [item])

  React.useEffect(() => {
    // 狀態切換時，有些marker已存在的情況下，會被隱藏
    setTimeout(() => {
      clusterer?.redraw()
    }, 300)
  }, [clusterer?.clusters]) //eslint-disable-line

  React.useEffect(() => {
    // 關閉刪除window
    document.addEventListener('click', deleteWindow.close)
    return () => document.removeEventListener('click', deleteWindow.close)
  }, []) //eslint-disable-line

  React.useEffect(() => {
    if (
      !showLabel ||
      !markerPosition ||
      !markerIconRef.current ||
      !labelRef.current
    ) {
      return
    }
    const calculateDistance = () => {
      // 獲取 A 和 B 元素的邊界資訊
      const A = markerIconRef.current.getBoundingClientRect()
      const B = labelRef.current.getBoundingClientRect()

      // A 右上角 (x1, y1)
      const x1 = A.right // A 的右邊
      const y1 = A.top // A 的上邊

      // B 左下角 (x2, y2)
      const x2 = B.left // B 的左邊
      const y2 = B.bottom // B 的下邊

      // 計算 x 軸和 y 軸的距離
      const xDistance = x2 - x1
      const yDistance = y2 - y1

      setLabelLineRect({
        width: Math.abs(xDistance) + 12,
        height: Math.abs(yDistance) + 12,
      })
    }
    setTimeout(calculateDistance, 100)
    const handleReposition = ({ detail: { id } }: any) => {
      if (Number(id) === item.id) {
        setTimeout(calculateDistance, 100)
      }
    }
    window.addEventListener<any>('markerReposition', handleReposition)
    return () => {
      window.removeEventListener<any>('markerReposition', handleReposition)
    }
  }, [markerPosition, showLabel])

  if (!markerPosition?.lat || !markerPosition?.lng) {
    return <div />
  }

  return (
    <MarkerComponent
      icon={isStreetMode ? undefined : icon}
      ref={markerRef}
      title={isStreetMode ? item?.displayName || '' : ''}
      position={markerPosition as google.maps.LatLng}
      clusterer={clusterer}
      animation={focus ? google.maps.Animation.BOUNCE : undefined}
      onDrag={handleOnDrag}
      onDragEnd={handleOnDragEnd}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleLeftClick}
      onRightClick={handleRightClick}
      onVisibleChanged={handleOnMarkerVisibleChanged}
      zIndex={50}
      opacity={isStreetMode ? 1 : 0}
      draggable={draggable}>
      {showLabel ? (
        <OverlayViewF
          position={markerPosition as google.maps.LatLng}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
          <div
            style={{
              width: `${labelLineRect.width}0px`,
              height: `${labelLineRect.height}0px`,
              position: 'relative',
              transform: 'translate(0px, calc(-100% - 15px)) ',
            }}>
            <svg
              width={labelLineRect.width}
              height={labelLineRect.height}
              viewBox={`0 0 ${labelLineRect.width} ${labelLineRect.height}`}
              xmlns="http://www.w3.org/2000/svg"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                pointerEvents: 'none',
              }}>
              <line
                x1="0"
                y1={labelLineRect.height}
                x2={labelLineRect.width}
                y2="0"
                stroke="#4a4a4a"
                strokeWidth="2"
              />
            </svg>
          </div>
        </OverlayViewF>
      ) : null}
      <OverlayViewF
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        position={markerPosition as google.maps.LatLng}
        {...others}>
        <>
          {tooltip && tooltipWindow.visible && item?.displayName && (
            <MarkerInfoWindow
              position={markerPosition}
              options={{
                ...infoboxOptions,
                pixelOffset: new google.maps.Size(-1, -25),
              }}>
              <StyledTooltipWrapper>
                <StyledTootipContent className="notranslate">
                  {item?.displayName}
                </StyledTootipContent>
                <StyledTooltipArrow></StyledTooltipArrow>
              </StyledTooltipWrapper>
            </MarkerInfoWindow>
          )}
          {deleteWindow.visible && (
            <MarkerInfoWindow
              position={markerPosition}
              options={{
                ...infoboxOptions,
                pixelOffset: new google.maps.Size(100, 13),
              }}>
              <DeleteWindow
                data={item}
                onCancel={deleteWindow.toggle}
                deleteMarker={() => onDelete && onDelete(item)}
              />
            </MarkerInfoWindow>
          )}
          <StyledMarkerWrapper className={className} ref={markerIconRef}>
            <img src={icon} alt="" />
          </StyledMarkerWrapper>
        </>
      </OverlayViewF>
      {showLabel ? (
        <OverlayViewF
          position={markerPosition as google.maps.LatLng}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
          <MarkerNameLabel
            data-id={item.id}
            className="marker-label"
            ref={labelRef}>
            {item?.displayName}
          </MarkerNameLabel>
        </OverlayViewF>
      ) : null}
    </MarkerComponent>
  )
}

const StyledMarkerWrapper = styled.div`
  translate: -13px -30px;
`

const MarkerNameLabel = styled.div`
  position: relative;
  transform: translate(15px, -45px);
  background-color: rgba(255, 255, 255, 0.8);
  color: #4a4a4a;
  padding: 5px;
  border-radius: 5px;
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); */
  white-space: nowrap;
`

const StyledTooltipWrapper = styled.div`
  position: relative;
`

const StyledTootipContent = styled.div`
  background-color: ${p => p.theme.yellow100};
  border-radius: 2px;
  font-size: 13px;
  font-weight: normal;
  padding: 3px 8px;
  min-width: 68px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
`

const StyledTooltipArrow = styled.div`
  background-color: transparent;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid ${p => p.theme.yellow100};
`

const infoboxOptions = {
  visible: true,
  alignBottom: false,
  disableAutoPan: false,
  enableEventPropagation: false,
  boxClass: 'style-info-box',
  boxStyle: {
    overflow: 'hidden',
  },
}
