import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { isEmpty } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PAGE_SIZE, TYPE } from '@services/report'
import { Box } from '@shared/innmaxUI'
import { Col, Row, Spin } from 'antd'
import ChartTitle from '../ChartTitle'
import type { ICommonProps } from '../type'
import Pagination from './../PaginationV2'
import {
  initChart,
  tooltipHeaderFormat,
  tooltipPointFormat,
} from './chartOptions'

export function LinkRateChart(props: ICommonProps) {
  const { queryVariables, yAxisDateStr, dataSource, loading } = props
  const { t } = useTranslation()

  const [startIdx, setStartIdx] = React.useState(0)
  const chart = React.useRef<HighchartsReact.RefObject>(null)
  const query = React.useMemo(() => queryVariables, [queryVariables])
  const [options, setOptions] = React.useState(initChart)

  React.useEffect(() => {
    if (isEmpty(dataSource.content)) {
      return
    }
    const endIdx = startIdx + PAGE_SIZE

    const xAxisData = dataSource.xAxis?.slice(startIdx, endIdx)
    const yAxisData = dataSource.yAxis?.slice(startIdx, endIdx)
    setOptions((x: any) => {
      return {
        ...x,
        xAxis: {
          ...x.xAxis,
          title: {
            ...x.xAxis.title,
            text:
              query.reportType !== TYPE.DAY ? yAxisDateStr : t('report:days'),
          },
          categories: dataSource.xAxisLabels.slice(startIdx, endIdx),
        },
        yAxis: {
          ...x.yAxis,
          title: {
            ...x.yAxis.title,
            text: '',
          },
        },
        tooltip: {
          ...x.tooltip,
          formatter: function (): any {
            return (this as any).points.reduce(function (s: any, point: any) {
              return (
                s +
                tooltipPointFormat(
                  point,
                  true,
                  point?.series?.tooltipOptions?.valueSuffix
                )
              )
            }, tooltipHeaderFormat(this))
          },
        },
        series: [
          {
            name: t('report:rate'),
            type: 'column',
            data: xAxisData,
            tooltip: {
              valueSuffix: '%',
            },
          },
          {
            name: t('report:avg rate'),
            type: 'line',
            data: yAxisData,
            tooltip: {
              valueSuffix: '%',
            },
          },
        ],
      }
    })
  }, [dataSource, query, startIdx, yAxisDateStr])

  React.useEffect(() => {
    if (!loading) {
      chart?.current?.chart?.hideLoading()
      chart?.current?.chart?.setSize(null, null)
    } else {
      chart?.current?.chart?.showLoading()
    }
  }, [chart, loading])

  return (
    <Row gutter={[48, 48]} className="mr-40">
      <Col span={24}>
        <Pagination
          data={dataSource}
          className="my-40 ml-[65px]"
          pageSize={PAGE_SIZE}
          onChangePage={pg => {
            const idx = pg - 1
            setStartIdx(idx * PAGE_SIZE)
          }}
        />
      </Col>
      <Col span={24}>
        <Spin spinning={loading}>
          <Box position="relative">
            <Box
              position="absolute"
              top="-35px"
              left="25px"
              zIndex={1}
              fontSize="18px"
              className="font-medium text-report-color">
              %
            </Box>
            <div className="w-100 h-100 flex">
              <div className="flex flex-col justify-center text-report-color">
                <div>連</div>
                <div>線</div>
                <div>率</div>
              </div>
              <HighchartsReact
                ref={chart}
                highcharts={Highcharts}
                containerProps={{ style: { width: '100%', height: '100%' } }}
                options={options}
              />
            </div>
          </Box>
        </Spin>
      </Col>
      <ChartTitle {...props} />
    </Row>
  )
}

export default React.memo(LinkRateChart)
