import {
  DrawingManager,
  GoogleMap,
  LoadScriptProps,
  Rectangle,
  useJsApiLoader,
} from '@react-google-maps/api'
import env from '@shared/env'
import theme from '@theme'
import { mergeDeepRight, pathOr } from 'ramda'
import React from 'react'
import { IGoogleMapControlsOption, IGoogleMapProps } from '../map.types'
import { useInnmaxMap } from '../Provider'
import BackControl from './BackControl'
import BackRectangleSelectControl from './BackRectangleSelectControl'
import FilterControl from './FilterControl'
import LayerControl from './LayerControl'
import PlaceSearchControl from './PlaceSearchControl'
import { useGoogleMap } from './Provider'
import SatelliteControl from './SatelliteControl'
import ZoomControl from './ZoomControl'

const libraries: LoadScriptProps['libraries'] = [
  'places',
  'drawing',
  'visualization',
]

function MapContainer(
  props: IGoogleMapProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: env.googleMapsApiKey,
    libraries,
    region: 'zh-TW',
  })

  const { defaultCenter, defaultZoom, setGoogleMap } = useInnmaxMap()

  const {
    controlsOption = defaultGoogleMapOptions,
    onRectangleComplete,
    onRectangleClick,
    onFilterControlClick,
    onLayerControlClick,
    children,
    onStreetViewChanged,
    ...others
  } = props

  const [map, setMap] = React.useState<google.maps.Map | null>(null)
  const [rectangle, setRectangle] =
    React.useState<google.maps.Rectangle | null>()

  const [drawingMode, setDrawingMode] = React.useState<
    google.maps.drawing.OverlayType.RECTANGLE | undefined
  >(undefined)

  const { setIsStreetMode, setMapInstance, isStreetMode } = useGoogleMap()

  const handleStreetViewVisibleChanged = React.useCallback(
    (e: google.maps.StreetViewPanorama) => {
      window?.google?.maps?.event?.addListener(e, 'visible_changed', () =>
        setIsStreetMode(e.getVisible())
      )
    },
    []
  ) //eslint-disable-line

  React.useEffect(() => {
    onStreetViewChanged?.(isStreetMode)
  }, [isStreetMode])

  const onLoad = React.useCallback((map: google.maps.Map) => {
    map.setCenter(defaultCenter as google.maps.LatLng)
    map.setZoom(defaultZoom)
    handleStreetViewVisibleChanged(map.getStreetView())
    setMap(map)
    setMapInstance(map)
    setGoogleMap(map)
    if (typeof ref === 'function') {
      ref(map.getDiv() as any)
    } else if (ref) {
      ref.current = map.getDiv() as any
    }
  }, [])

  const onUnmount = React.useCallback((map: google.maps.Map) => {
    setMap(null)
  }, [])

  const controlOptions = mergeDeepRight(
    {
      streetViewControlOptions: {
        position: window?.google?.maps?.ControlPosition?.LEFT_BOTTOM,
      },
      zoomControlOptions: {
        position: window?.google?.maps?.ControlPosition?.BOTTOM_RIGHT,
        styles: { margin: '0 40px 40px 0' },
      },
      backControlOptions: {
        position: window?.google?.maps?.ControlPosition?.RIGHT_BOTTOM,
        styles: { margin: '24px 40px 24px 0' },
      },
      backRectangleSelectControlOptions: {
        position: window?.google?.maps?.ControlPosition?.RIGHT_BOTTOM,
        styles: { margin: '24px 40px 24px 0' },
      },
      placeSearchControlOptions: {
        position: window?.google?.maps?.ControlPosition?.TOP_RIGHT,
        styles: { margin: '44px 40px 0 0' },
      },
      filterControlOptions: {
        position: window?.google?.maps?.ControlPosition?.RIGHT_TOP,
        styles: { margin: '24px 40px 0 0' },
      },
      layerControlOptions: {
        position: window?.google?.maps?.ControlPosition?.RIGHT_TOP,
        styles: { margin: '18px 40px 0 0' },
      },
      satelliteControlOption: {
        position: window?.google?.maps?.ControlPosition?.BOTTOM_LEFT,
        styles: { margin: '0 0 40px 14px' },
      },
    },
    controlsOption
  )

  const handleRectangleComplete = React.useCallback(
    (rectangle: google.maps.Rectangle) => {
      google.maps.event.clearInstanceListeners(rectangle)
      rectangle.setMap(null)
      setRectangle(rectangle)
      setDrawingMode(undefined)
      onRectangleComplete && onRectangleComplete(rectangle)
    },
    [onRectangleComplete]
  )

  const handleOnRectangleClick = React.useCallback(
    (e: google.maps.MapMouseEvent) => {
      setRectangle(null)
      setDrawingMode(undefined)
      onRectangleClick && onRectangleClick(e)
    },
    [onRectangleClick]
  )

  const handleBackToDefaultCenter = () => {
    map?.setZoom(defaultZoom)
    map?.panTo(defaultCenter as google.maps.LatLng)
  }

  const handleRectangleSelect = () => {
    setDrawingMode(
      !drawingMode ? google.maps.drawing.OverlayType.RECTANGLE : undefined
    )
  }

  const renderPlugins = [
    {
      name: 'zoomControlOptions',
      component: (
        <ZoomControl {...(controlOptions.zoomControlOptions as any)} />
      ),
    },
    {
      name: 'backControl',
      component: (
        <BackControl
          onClick={handleBackToDefaultCenter}
          {...(controlOptions.backControlOptions as any)}
        />
      ),
    },
    {
      name: 'backRectangleSelectControl',
      component: (
        <>
          <DrawingManager
            drawingMode={drawingMode}
            options={drawingManagerOptions}
            onRectangleComplete={handleRectangleComplete}
          />
          <Rectangle
            bounds={rectangle?.getBounds() || undefined}
            options={rectangleOptions}
            onClick={handleOnRectangleClick}
          />
          <BackRectangleSelectControl
            isDrawingMode={
              drawingMode ===
              window?.google?.maps?.drawing?.OverlayType?.RECTANGLE
            }
            back={handleBackToDefaultCenter}
            select={handleRectangleSelect}
            {...controlOptions.backRectangleSelectControlOptions}
          />
        </>
      ),
    },
    {
      name: 'placeSearchControl',
      component: (
        <PlaceSearchControl
          onPlaceSelected={(place: google.maps.places.PlaceResult) => {
            if (place.geometry) {
              map?.fitBounds(
                new google.maps.LatLngBounds(place?.geometry?.viewport)
              )
              // setTimeout(() => setMapCenter(place?.geometry?.location?.toJSON()), 500)
            }
          }}
          {...controlOptions.placeSearchControlOptions}
        />
      ),
    },
    {
      name: 'filterControl',
      component: (
        <FilterControl
          onClick={onFilterControlClick}
          {...controlOptions.filterControlOptions}
        />
      ),
    },
    {
      name: 'layerControl',
      component: (
        <LayerControl
          onClick={onLayerControlClick}
          {...controlOptions.layerControlOptions}
        />
      ),
    },
    {
      name: 'satelliteControl',
      component: (
        <SatelliteControl {...controlOptions.satelliteControlOption} />
      ),
    },
  ]
    .filter(plugin => pathOr(false, [plugin.name], controlOptions))
    .map(plugin => React.cloneElement(plugin?.component, { key: plugin.name }))

  return isLoaded ? (
    <GoogleMap
      zoom={defaultZoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={mergeDeepRight(googleMapOptions, controlOptions) as any}
      {...others}>
      {renderPlugins}
      {children}
    </GoogleMap>
  ) : (
    <></>
  )
}

export default React.forwardRef(MapContainer)

const rectangleOptions = {
  ...theme.n.map.rectangleOptions,
}

const drawingManagerOptions = {
  drawingControl: false,
  rectangleOptions,
}

const defaultGoogleMapOptions: IGoogleMapControlsOption = {
  zoomControl: false,
  backRectangleSelectControl: false,
  placeSearchControl: false,
  filterControl: false,
  layerControl: false,
  satelliteControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  rotateControl: false,
  fullscreenControl: false,
}

export const googleMapOptions = {
  mapTypeControl: false,
  fullscreenControl: false,
  controlSize: 28,
  styles: [
    {
      featureType: 'all',
      stylers: [
        {
          saturation: 0,
        },
        {
          hue: '#e7ecf0',
        },
      ],
    },
    {
      featureType: 'road',
      stylers: [
        {
          saturation: -70,
        },
      ],
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          saturation: -60,
        },
      ],
    },
  ],
}
