import type {
  IDeviceCountInfoBySessionId,
  ISession,
  ISessionStatistical,
} from '@services/session'
import envStore from '@shared/env'
import request from '@shared/innmaxLib/services'
import { usePersistQuery } from '@shared/innmaxLib/services/usePersistQuery'
import { groupBy, pathOr, pluck, prepend, sum, toPairs } from 'ramda'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useConvertSessionData } from './session.converter'
import { ISessionTreeNode } from './session.types'

export const useAllSessions = () => {
  const { data, ...others } = useQuery('/sessionInfo/list/all', {
    cacheTime: Infinity,
  })

  const sessionData = useConvertSessionData(data)

  const findStoreSession = React.useCallback(
    (id: number) => {
      function loopFind(id: number): ISession | undefined {
        if (!sessionData.byId) {
          return undefined
        }
        if (!sessionData.byId[id]) {
          return undefined
        }
        const parentId = sessionData.byId[id].parentSessionId
        if (!!parentId && sessionData.byId[parentId].type === 3) {
          return loopFind(parentId)
        }
        return sessionData.byId[id]
      }
      return loopFind(id)
    },
    [sessionData.byId]
  )

  const getFullSessions = React.useCallback(
    (id: number) => {
      function loopFind(id: number, sessions: ISession[]): ISession[] {
        if (!sessionData.byId) {
          return sessions
        }
        if (!sessionData.byId[id]) {
          return sessions
        }
        const parentId = sessionData.byId[id].parentSessionId
        if (!!parentId) {
          return loopFind(parentId, prepend(sessionData.byId[id], sessions))
        }
        return prepend(sessionData.byId[id], sessions)
      }
      return loopFind(id, [])
    },
    [sessionData.byId]
  )

  return {
    ...others,
    byId: sessionData.byId || {},
    dataSource: sessionData.dataSource,
    data: sessionData.data || [],
    findStoreSession,
    getFullSessions,
  }
}

export const useSessionStatistical = (query?: Record<string, any>) => {
  const { data, ...others } = useQuery(
    ['/sessionInfo/list/statistical', query],
    {
      // refetchInterval: 10 * 1000,
      // refetchOnMount: true,
      // refetchOnMount: true,
    }
  )

  const dataSource = useConvertSessionData(data)

  const deviceCountInfoBySessionId =
    React.useMemo<IDeviceCountInfoBySessionId>(() => {
      const statistical = pathOr<ISessionStatistical[]>([], ['data'], data)
      const result: IDeviceCountInfoBySessionId = {}

      toPairs(groupBy(s => s.id + '', statistical)).forEach(
        ([sessionId, devices]) => {
          result[sessionId] = {
            deviceCount: sum(pluck('deviceCount', devices)),
            deviceAlarmCount: sum(pluck('deviceAlarmCount', devices)),
            deviceNormalCount: sum(pluck('deviceNormalCount', devices)),
            deviceRepairCount: sum(pluck('deviceRepairCount', devices)),
          }
        }
      )
      return result
    }, [data])

  return {
    ...others,
    deviceCountInfoBySessionId,
    dataSource,
  }
}

export const useGetSessionTree = () => {
  const { data, refetch, ...others } = usePersistQuery<{
    data: ISessionTreeNode[]
  }>(['/sessionInfo/tree/all'], {
    refetchedErrorCodes: [-51, -52, -53, -54, -55, -56, -57, -58],
  })
  const dataSource = React.useMemo(() => {
    const loopChildren = (sessions: ISessionTreeNode[]) => {
      return sessions.map(s => {
        if (s.sessions) {
          s.sessions = loopChildren(s.sessions)
        }
        return {
          ...s,
          layerFileUrl: `${envStore.uploadFileUrlPrefix}${s.layerFilePath}`,
        }
      })
    }
    let result = data?.data
    if (result) {
      result = loopChildren(result)
    }
    return { data: result }
  }, [data])

  const queryClient = useQueryClient()

  const _refetch = React.useCallback(() => {
    refetch()
    queryClient.invalidateQueries('/sessionInfo/list/all', {
      exact: false,
    })
  }, [queryClient, refetch])

  return {
    ...others,
    data: dataSource,
    refetch: _refetch,
  }
}

export const useAddSession = (options?: any) =>
  useMutation(
    body =>
      request('/sessionInfo', {
        method: 'POST',
        body,
      }),
    options
  )

export const useEditSession = (options?: any) =>
  useMutation(
    body =>
      request('/sessionInfo', {
        method: 'PUT',
        body,
      }),
    options
  )

export const useDeleteSession = (options?: any) =>
  useMutation(
    (body: { id: number }) =>
      request('/sessionInfo', {
        method: 'DELETE',
        body,
      }),
    options
  )

export default useAllSessions
