import {
  MarkerClusterer,
  MarkerClustererProps,
  Marker as MarkerComponent,
  OverlayView,
  OverlayViewF,
} from '@react-google-maps/api'
import {
  Cluster,
  Clusterer,
  ClusterIconStyle,
  MarkerExtended,
} from '@react-google-maps/marker-clusterer'
import cx from 'classnames'
import React from 'react'
import styled from 'styled-components'

import { DeviceStatus, DeviceStatusStr } from '@services/constants'
import { imageUrlPrefix } from '@shared/env'
import { useGoogleMap } from './Provider'

interface MarkerWithClustererProps extends MarkerClustererProps {
  clusterStyles?: ClusterIconStyle[] | undefined
}

const DEFAULT_CLUSTER_STYLES = [
  {
    url: `${imageUrlPrefix}/cluster/cluster-empty.png`,
    width: 40,
    height: 40,
    textColor: '#fff',
    textSize: 12,
  },
  {
    url: `${imageUrlPrefix}/cluster/cluster-empty.png`,
    width: 40,
    height: 40,
    textColor: '#fff',
    textSize: 12,
  },
  {
    url: `${imageUrlPrefix}/cluster/cluster-empty.png`,
    width: 40,
    height: 40,
    textColor: '#fff',
    textSize: 12,
  },
  {
    url: `${imageUrlPrefix}/cluster/cluster-empty.png`,
    width: 40,
    height: 40,
    textColor: '#fff',
    textSize: 12,
  },
  {
    url: `${imageUrlPrefix}/cluster/cluster-empty.png`,
    width: 40,
    height: 40,
    textColor: '#fff',
    textSize: 12,
  },
]

export default function MarkerWithClusterer({
  children,
  onClick,
  clusterStyles = DEFAULT_CLUSTER_STYLES,
}: MarkerWithClustererProps) {
  const [clusters, setClusters] = React.useState<Cluster[]>([])

  const { map } = useGoogleMap()

  const clustererRef = React.useRef<any>()

  const className = React.useCallback((markers = []) => {
    let status: DeviceStatus = DeviceStatus.NORMAL
    Object.entries(markers).map(([index, item]: any) => {
      const icon = item?.icon
      if (/(repair)/.test(icon)) {
        status = DeviceStatus.REPAIR
      } else if (/(alarm)/.test(icon)) {
        status = DeviceStatus.ALARM
      }
      return status
    })
    return cx(DeviceStatusStr[status], 'cluster z-40')
  }, []) //eslint-disable-line

  const handleClusteringBegin = React.useCallback(
    (markerClusterer: Clusterer) => {
      markerClusterer.markers.forEach(marker => {
        if (marker) {
          marker.setVisible(false)
        }
      })
    },
    []
  ) //eslint-disable-line

  const handleClusteringEnd = React.useCallback(
    (markerClusterer: Clusterer) => {
      setClusters(
        markerClusterer.clusters.filter(clusters => clusters.markers.length > 1)
      )
      markerClusterer.clusters.forEach(cluster => {
        const _markers = cluster.getMarkers()
        if (_markers.length === 1) {
          const notClusteredMarker: any = _markers[0]
          const notClusteredMarkerPosition = notClusteredMarker?.position
          _markers.forEach((marker: MarkerExtended) => {
            if (
              marker &&
              marker.getPosition()?.lat() ===
                notClusteredMarkerPosition.lat() &&
              marker.getPosition()?.lng() === notClusteredMarkerPosition.lng()
            ) {
              setTimeout(() => marker.setVisible(true), 0)
            }
          })
        }
      })
    },
    []
  ) //eslint-disable-line

  const handleClusterClick = React.useCallback(
    (cluster: Cluster) => {
      onClick && onClick(cluster)
    },
    [onClick]
  ) //eslint-disable-line

  return (
    // mobile模式下clusterer點擊事件會同時觸發map被點擊事件，所以改用marker方式處理
    <>
      {clusters.map((c, index) => (
        <MarkerComponent
          key={index}
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            scale: 13,
          }}
          opacity={0}
          zIndex={40}
          position={c.getCenter() as google.maps.LatLng}
          onClick={() => {
            handleClusterClick(c)
            setTimeout(() => map?.fitBounds(c.getBounds()))
          }}>
          <OverlayViewF
            mapPaneName={OverlayView.MARKER_LAYER}
            position={c.getCenter()}>
            <StyledMarkerWrapper className={className(c.getMarkers() as [])}>
              {c.getMarkers()?.length}
            </StyledMarkerWrapper>
          </OverlayViewF>
        </MarkerComponent>
      ))}
      <MarkerClusterer
        ref={clustererRef}
        onLoad={handleClusteringBegin}
        onClusteringBegin={handleClusteringBegin}
        onClusteringEnd={handleClusteringEnd}
        styles={clusterStyles}
        clusterClass="invisible">
        {children}
      </MarkerClusterer>
    </>
  )
}

const StyledMarkerWrapper = styled(({ ...props }) => <div {...props} />)`
  margin-left: -20px;
  margin-top: -20px;
`
