import { DeviceStatus } from '@services/constants'
import { Device } from '@services/device'
import cx from 'classnames'
import { any, equals, pluck } from 'ramda'
import React from 'react'
import { css } from 'styled-components'
import GoogleMap from '../Google'
import { IInnmaxClusterProps, MapType } from '../map.types'
import MapLibre from '../MapLibre'
import { useInnmaxMap } from '../Provider'
import { InnmaxMarker } from './InnmaxMarker'

export const InnmaxCluster = React.memo((props: IInnmaxClusterProps) => {
  const { mapPlatform, mapType } = useInnmaxMap()

  if (mapType === MapType.Upload || mapPlatform === 'MapLibre') {
    return <MapLibreCluster {...props} />
  }
  return <GoogleCluster {...props} />
})

const MapLibreCluster = React.memo((props: IInnmaxClusterProps) => {
  const { devices, onLeftClick } = props

  const createCluster = (devices: Device[]) => {
    let statusClass = 'normal'
    const statusList = pluck('deviceStatus', devices)
    if (any(equals(DeviceStatus.REPAIR), statusList)) {
      statusClass = 'repair'
    }
    if (any(equals(DeviceStatus.ALARM), statusList)) {
      statusClass = 'alarm'
    }
    return (
      <div
        className={cx('cluster-marker', statusClass)}
        css={css`
          width: 45px;
          height: 45px;
        `}>
        {devices.length}
      </div>
    )
  }

  return (
    <>
      <MapLibre.MarkerCluster
        pointItems={devices}
        onRenderMarkerCluster={createCluster}>
        {devices =>
          devices.map(device => (
            <InnmaxMarker.DeviceMarker
              key={`${device.deviceType}-${device.id}`}
              item={device}
              onLeftClick={onLeftClick}
            />
          ))
        }
      </MapLibre.MarkerCluster>
    </>
  )
})

const GoogleCluster = React.memo((props: IInnmaxClusterProps) => {
  const { devices, onLeftClick } = props

  return (
    <GoogleMap.Clusterer>
      {(clusterer: any) => (
        <>
          {devices.map((device: Device) => (
            <InnmaxMarker.DeviceMarker
              key={`${device.deviceType}-${device.id}`}
              item={device}
              onLeftClick={onLeftClick}
              clusterer={clusterer}
            />
          ))}
        </>
      )}
    </GoogleMap.Clusterer>
  )
})
