import { Button } from '@shared/Form'
import cx from 'classnames'
import React from 'react'

import { isNil } from 'ramda'
import leftIcon from './ic-arrowlight.svg'
import rightIcon from './ic-arrowright.svg'
const btnStyle = {
  minWidth: 36,
  padding: '0px',
  borderRadius: '3px',
  border: `solid 1.5px #fff`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

type Props = {
  pageSize: number
  data: any
  onChangePage?: (v: number) => void
  onResetPage?: () => void
  className?: string
  style?: object
  children?: React.ReactNode
}

function Pagination({
  data,
  pageSize,
  className,
  onChangePage,
  ...props
}: Props) {
  const [currentPage, setCurrentPage] = React.useState(1)
  console.log('data', data)
  const totalPages = React.useMemo(
    () =>
      isNil(data?.totalPages)
        ? Math.ceil(data?.total / pageSize)
        : data?.totalPages,
    [data, pageSize]
  )

  const handleNextPage = React.useCallback(() => {
    const pg = currentPage + 1

    setCurrentPage(pg)
  }, [currentPage])

  const handlePrevPage = React.useCallback(() => {
    const pg = currentPage - 1

    setCurrentPage(pg)
  }, [currentPage])

  React.useEffect(() => {
    onChangePage && onChangePage(currentPage)
  }, [currentPage])

  return (
    <div className={cx('flex justify-between', className)} {...props}>
      {currentPage === 1 ? <span></span> : null}
      {totalPages > 1 && currentPage > 1 && (
        <Button type="primary" ghost style={btnStyle} onClick={handlePrevPage}>
          <img src={leftIcon} alt="left" />
        </Button>
      )}
      {totalPages > 1 && currentPage < totalPages && (
        <Button type="primary" ghost style={btnStyle} onClick={handleNextPage}>
          <img src={rightIcon} alt="rigth" />
        </Button>
      )}
    </div>
  )
}

export default React.memo(Pagination)
