// 建立 google map 的 react context 與 provider, 包含以下功能：
// 1. 提供 google map instance 的 get 方法
// 2. 提供 google map instance 的 set 方法

import React from 'react'
import { useInnmaxMap } from '../Provider'

type IGoogleMapContext = {
  map: google.maps.Map | null
  setMapInstance: (map: google.maps.Map) => void
  isStreetMode: boolean
  setIsStreetMode: (visible: boolean) => void
}

export const GoogleMapContext = React.createContext<IGoogleMapContext>({
  map: null,
  setMapInstance: () => {},
  isStreetMode: false,
  setIsStreetMode: () => {},
})

export const GoogleMapProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [map, setMap] = React.useState<google.maps.Map | null>(null)
  const [isStreetMode, setIsStreetMode] = React.useState(false)

  const { setGoogleMap } = useInnmaxMap()

  const contextValue = React.useMemo(() => {
    return {
      map,
      setMapInstance: (map: google.maps.Map) => {
        setMap(map)
        setGoogleMap(map)
      },
      isStreetMode,
      setIsStreetMode,
    }
  }, [isStreetMode, map])

  return (
    <GoogleMapContext.Provider value={contextValue}>
      {children}
    </GoogleMapContext.Provider>
  )
}

export const useGoogleMap = () => {
  const context = React.useContext(GoogleMapContext)
  if (!context) {
    throw new Error('useGoogleMap must be used within a GoogleMapProvider')
  }
  return context
}
