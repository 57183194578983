import { Input } from 'antd'
import styled from 'styled-components'

const TextArea = styled(Input.TextArea)`
  resize: none;
  background-color: ${p => p.theme.input.bg};
  border-color: ${p => p.theme.input.borderColor};
  color: ${p => p.theme.input.color};
  &::placeholder {
    color: ${p => p.theme.input.placeholderColor};
  }
  &.ant-input:hover,
  &.ant-input:focus,
  &.ant-input:active {
    border-color: ${p => p.theme.input.hoverAndFocusBorderColor};
  }

  &.ant-input[disabled] {
    background: ${p => p.theme.input.disabledBg} !important;
    color: ${p => p.theme.input.disabledColor} !important;
  }
`

export default TextArea
