import { ReportTable } from '@shared/Table'
import { Col, Row, Spin } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { isEmpty, slice } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ILinkRate } from '@services/report'
import ReportTitle from '../CommonTitle'
import type { ICommonProps } from '../type'

export const pageSize = 10

function LinkRateReport({
  queryVariables,
  dataSource,
  loading,
  ...props
}: ICommonProps) {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = React.useState(1)

  const columns: ColumnProps<ILinkRate>[] = [
    {
      dataIndex: 'displayDate',
      title: t('report:time'),
      width: 160,
    },
    {
      dataIndex: 'rate',
      title: `連線率 %`,
      width: 220,
    },
    {
      dataIndex: 'avgRate',
      title: `平均連線率 %`,
      width: 220,
    },
  ]

  React.useEffect(() => {
    setCurrentPage(1)
  }, [queryVariables.conditionDate, queryVariables.reportType])

  return isEmpty(dataSource.content) ? (
    <div className="w-full h-full flex justify-center items-center">
      <Spin spinning={loading} />
    </div>
  ) : (
    <Row className="mt-42">
      <ReportTitle total={dataSource.content.length} {...props} />
      <Col span={16}>
        <ReportTable
          rowKey="date"
          dataSource={{
            ...(dataSource as any),
            page: currentPage,
            content: slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize,
              dataSource.content
            ),
          }}
          loading={loading}
          columns={columns}
          pagination={{
            showSizeChanger: false,
            total: dataSource?.content.length || 1,
            pageSize,
          }}
          onChange={(p: any) => setCurrentPage(p.current || 1)}
        />
      </Col>
    </Row>
  )
}

export default React.memo(LinkRateReport)
