import { DeviceType } from '@services/constants'
import { QueryVariables } from '@shared/innmaxLib/services'
import { useQuery } from 'react-query'
import useHandleControllerResData from './controller.hook'

export const useDeviceCtrlerType = (
  variables: { deviceType: string },
  options: QueryVariables = {}
) => {
  const apiPath =
    variables.deviceType === DeviceType.LAMP_CTLER
      ? '/device/streetlight/lamp/ctrler/types'
      : '/device/{deviceType}/ctrler/types'
  const { data, ...others } = useQuery([apiPath, variables], options)

  const { dataSource, getColumnsByType } = useHandleControllerResData(data)

  return {
    getColumnsByType,
    ...others,
    loading: others.isLoading,
    dataSource,
  }
}
