import { Tabs } from 'antd'
import styled from 'styled-components'

const StyledTabs = styled(Tabs)`
  &.ant-tabs {
    &.ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: ${p => p.theme.tabs.border};
    }
    .ant-tabs-tab {
      width: 144px;
      display: flex;
      justify-content: center;
      padding-bottom: 4px;
      color: ${p => p.theme.tabs.color};
    }

    .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      margin-bottom: 0px !important;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 0;
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
      font-size: 15px;
    }

    .ant-tabs-tab:hover,
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${p => p.theme.tabs.activeColor};
    }

    .ant-tabs-ink-bar {
      background: ${p => p.theme.tabs.activeColor};
    }
  }
`

export default StyledTabs
