import Input from '@shared/Form/Input'
import useViewport from '@shared/innmaxLib/hooks/useViewport'
import { Column } from '@shared/Layout'
import { Form } from 'antd'
import type { FormItemProps, Rule } from 'antd/lib/form'
import cx from 'classnames'
import { isNil, pathOr } from 'ramda'
import React from 'react'

export const Asterisk = ({ className }: { className?: string }) => (
  <span
    className={cx('text-danger ml-2', className)}
    css={{ position: 'relative', top: 3 }}>
    *
  </span>
)

export interface FieldProps extends FormItemProps {
  // name: string
  value?: any
  isEdit?: boolean
  children: React.ReactNode
  // rules?: Rule[]
}

export const Field = ({
  name,
  value,
  isEdit,
  children,
  ...props
}: FieldProps) => {
  if (!isEdit) {
    return value
  }

  return (
    <Form.Item noStyle name={name} {...props}>
      {children}
    </Form.Item>
  )
}

interface RenderFieldProps extends Omit<FormFieldsProps, 'rules'> {
  rules?: Rule[]
}

export interface FormFieldsProps {
  title: string[]
  field?: string[]
  requiredField?: any[]
  rules?: [Rule[], Rule[]]
  className?: any
  isEdit?: boolean
  editable?: boolean
  item?: any
  renderLeft?: (props: RenderFieldProps) => React.ReactNode
  renderRight?: (props: RenderFieldProps) => React.ReactNode
}

export function TableColumn(props: FormFieldsProps) {
  const {
    title,
    field = [],
    requiredField = [false, false],
    rules = [],
    renderLeft,
    renderRight,
    editable = true,
    className,
    isEdit,
    item,
  } = props

  const { isMobile } = useViewport()

  const ColumnField = Column

  const [title1, title2] = title
  const [field1, field2] = field
  const [rules1, rules2] = rules
  const [required_field1, required_field2] = requiredField

  const render = (
    name: string,
    renderer?: (props: RenderFieldProps) => React.ReactNode,
    rules?: Rule[]
  ) => {
    if (renderer) {
      return renderer({ ...props, rules: rules as any })
    }
    if (isEdit && editable) {
      return (
        <Form.Item noStyle name={name} rules={rules}>
          <Input className="w-full" />
        </Form.Item>
      )
    }

    return pathOr('', [name], item)
  }

  return isMobile ? (
    <>
      <div className="flex-1 flex mobile-col">
        <ColumnField>
          {title1}
          {required_field1 && <Asterisk />}
        </ColumnField>
        <ColumnField className={cx('flex-1', className)}>
          {render(field1, renderLeft, rules1)}
        </ColumnField>
      </div>

      {!isNil(title2) && (
        <div className="flex-1 flex mobile-col">
          <ColumnField>
            {title2}
            {required_field2 && <Asterisk />}
          </ColumnField>
          <ColumnField className={cx('flex-1', className)}>
            {render(field2, renderRight, rules2)}
          </ColumnField>
        </div>
      )}
    </>
  ) : (
    <div className="form-column-row flex">
      <ColumnField>
        {title1}
        {required_field1 && <Asterisk />}
      </ColumnField>
      <ColumnField className={cx('flex-1', className)}>
        {render(field1, renderLeft, rules1)}
      </ColumnField>
      {!isNil(title2) && (
        <>
          <ColumnField>
            {title2}
            {required_field2 && <Asterisk />}
          </ColumnField>
          <ColumnField className={cx('flex-1', className)}>
            {render(field2, renderRight, rules2)}
          </ColumnField>
        </>
      )}
    </div>
  )
}
