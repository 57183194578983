import GoogleMapContainer from './MapContainer'
import SessionMarker from './SessionMarker'
import DeviceMarker from './DeviceMarker'
import Clusterer from './Clusterer'
import { GoogleMapProvider, useGoogleMap } from './Provider'

type IGoogleMapContainer = typeof GoogleMapContainer

interface IInternalGoogleMap extends IGoogleMapContainer {
  //   Provide: typeof Provider
  //   LayerToogle: typeof LayerToogle
  //   ZoomControl: typeof ZoomControlFC
  //   Marker: typeof Marker
  //   Tooltip: typeof Tooltip
  //   Popup: typeof Popup
  //   MarkerCluster: typeof MarkerCluster
  DeviceMarker: typeof DeviceMarker
  SessionMarker: typeof SessionMarker
  Clusterer: typeof Clusterer
  Provider: typeof GoogleMapProvider
  useGoogleMap: typeof useGoogleMap
}

const GoogleMap: IInternalGoogleMap = GoogleMapContainer as IInternalGoogleMap
// MapLibre.Provide = Provider
// MapLibre.LayerToogle = LayerToogle
// MapLibre.ZoomControl = ZoomControlFC
// MapLibre.Marker = Marker
// MapLibre.Tooltip = Tooltip
// MapLibre.Popup = Popup
// MapLibre.MarkerCluster = MarkerCluster
GoogleMap.DeviceMarker = DeviceMarker
GoogleMap.SessionMarker = SessionMarker
GoogleMap.Clusterer = Clusterer
GoogleMap.Provider = GoogleMapProvider
GoogleMap.useGoogleMap = useGoogleMap

export default GoogleMap
