import { RefProps } from '@lib/types'
import { DatePicker } from 'antd'
import { DatePickerProps } from 'antd/lib/date-picker'
import cx from 'classnames'
import React from 'react'
import styled from 'styled-components'

type Props = DatePickerProps & RefProps

const StyledDatePicker = styled(DatePicker)`
  &.ant-picker {
    border-radius: 4px;
    background-color: ${p => p.theme.datePicker.bg};
    border-color: ${p => p.theme.datePicker.borderColor};
    color: ${p => p.theme.datePicker.color};
    &:hover,
    &:focus {
      border-color: ${p => p.theme.datePicker.hoverAndFocusBorderColor};
    }
    > .ant-picker-input {
      > input::placeholder {
        color: ${p => p.theme.datePicker.placeholderColor};
      }
      > .ant-picker-clear svg[data-icon='close-circle'] {
        fill: ${p => p.theme.datePicker.clearIconFill};
      }
      > .ant-picker-suffix svg[data-icon='calendar'] {
        fill: ${p => p.theme.datePicker.calendarIconFill};
      }
    }
    &.ant-picker-disabled {
      background: ${p => p.theme.datePicker.disabledBg};
      color: ${p => p.theme.datePicker.disabledColor};
    }
  }
`

const DatePickerComponent = React.forwardRef<typeof DatePicker, Props>(
  ({ className = '', ...props }, ref) => (
    <StyledDatePicker
      // forwardRef={ref}
      className={cx('c-datepicker', className)}
      placeholder=""
      {...props}
    />
  )
)

DatePickerComponent.displayName = 'DatePicker'

export default DatePickerComponent
