import { DatePicker, RangePicker } from '@shared/Form/DatePicker'
import { Select, SelectProps } from '@shared/Form/Select'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { IQueryProps, ReportSearchType, TYPE } from '@services/report'
import { css } from 'styled-components'

type Props = {
  onChange?: (val?: any) => void
  tenMinutesSupport?: boolean
  dailyHourSupport?: boolean
  rangeDateDisabled?: {
    start?: 'disabledAfter' | 'disabledBefore'
    end?: 'disabledBefore' | 'disabledAfter'
  }
  /**限制區間報區間最多兩個月 */
  maxTwoMonthSelected?: boolean
} & Omit<SelectProps, 'onChange'>

export default function ReportRangeTimeSelect({
  onChange,
  tenMinutesSupport = false,
  dailyHourSupport = false,
  rangeDateDisabled,
  maxTwoMonthSelected,
  ...props
}: Props) {
  const { t } = useTranslation()

  const [value, setValue] = React.useState<IQueryProps>({
    reportType: TYPE.YEAR,
    conditionDate: new Date().getFullYear(),
    start: moment().startOf('year').valueOf(),
    end: moment().endOf('year').valueOf(),
  })

  const rangePickerRef = React.useRef<HTMLDivElement | null>(null)

  const [rangeDateType, setRangeDateType] = React.useState<
    'start' | 'end' | null
  >(null)

  const currentYear = React.useMemo(() => new Date().getFullYear(), [])

  const currentMonth = React.useMemo(() => new Date().getMonth() + 1, [])

  const handleDisabledDate = (current: any) => {
    if (value.reportType !== TYPE.DAY) {
      return current.year() < 2023 || current >= moment().endOf('day')
    }
    if (maxTwoMonthSelected && value.reportType === TYPE.DAY) {
      if (rangeDateType === 'start') {
        return current.year() < 2023 || current > moment().endOf('day')
      }
      return (
        current > moment().endOf('day') ||
        current > moment(value.start).add(2, 'month').endOf('day')
      )
    }
    if (!rangeDateDisabled) {
      return current.year() < 2023 || current >= moment().endOf('day')
    }

    const rangeDisabledType =
      rangeDateType === 'start'
        ? rangeDateDisabled.start
        : rangeDateDisabled.end

    if (rangeDisabledType === 'disabledAfter') {
      return current > moment().endOf('day')
    }
    if (rangeDisabledType === 'disabledBefore') {
      return current < moment().startOf('day')
    }
    return false
  }

  const handleReportType = (val: any) => {
    let start = moment().startOf('day')
    let end = moment().endOf('day')
    let conditionDate: undefined | string | number = undefined
    switch (val) {
      case TYPE.YEAR:
        start = moment().startOf('year')
        end = moment().endOf('year')
        conditionDate = currentYear
        break
      case TYPE.MONTH:
        start = moment().startOf('month')
        end = moment().endOf('month')
        conditionDate = `${currentYear}/${currentMonth}`
        break
      case TYPE.DAY:
        start = moment().subtract(1, 'month').startOf('day')
        end = moment().endOf('day')
        conditionDate = undefined
    }
    setValue({ reportType: val, start, end, conditionDate })
  }

  const handleChangeYM = (val: any) => {
    const type = value.reportType === TYPE.YEAR ? 'year' : 'month'
    setValue((prev: any) => ({
      ...prev,
      start: val.startOf(type),
      end: val.endOf(type),
      conditionDate:
        value.reportType === TYPE.YEAR
          ? moment(val).format('YYYY')
          : `${moment(val).format('YYYY')}/${moment(val).format('M')}`,
    }))
  }

  const handleChangeSingleDate = (val: any) => {
    setValue((prev: any) => ({
      ...prev,
      conditionDate: undefined,
      start: val ? val.startOf('day').valueOf() : undefined,
      end: val ? val.endOf('day').valueOf() : undefined,
    }))
  }

  const handleChangeDate = (val: any) => {
    const [start, end] = val ? val : []
    let _end = end
    if (maxTwoMonthSelected && value.reportType === TYPE.DAY) {
      if (start && end && start.add(2, 'month').isBefore(end)) {
        _end = start.add(2, 'month')
      }
    }
    setValue((prev: any) => ({
      ...prev,
      conditionDate: undefined,
      start,
      end: _end,
    }))
  }

  React.useEffect(() => {
    onChange && onChange(value)
  }, [value]) //eslint-disable-line

  React.useEffect(() => {
    if (value.reportType === TYPE.DAY) {
      const rangeInputs =
        rangePickerRef.current?.querySelectorAll('.ant-picker-input > input') ||
        []

      const handleFocus = (index: number) => {
        setRangeDateType(index === 0 ? 'start' : 'end')
      }

      rangeInputs.forEach((input: Element, index: number) => {
        input.addEventListener('focus', () => {
          handleFocus(index)
        })
      })

      return () => {
        rangeInputs.forEach((input: Element, index: number) => {
          input.removeEventListener('focus', () => {
            handleFocus(index)
          })
        })
      }
    }
  }, [value.reportType])

  return (
    <div ref={rangePickerRef}>
      <Select
        value={value.reportType}
        allowClear={false}
        css={css`
          width: 104px !important;
          margin-right: 10px;
        `}
        onChange={handleReportType}>
        {Object.values(TYPE)
          .filter(t => [TYPE.TEN_MINUTES, TYPE.DAILY_HOUR].indexOf(t) === -1)
          .map(i => (
            <Select.Option key={i} value={i}>
              {ReportSearchType[i]}
            </Select.Option>
          ))}
        {dailyHourSupport && (
          <Select.Option value={TYPE.DAILY_HOUR}>
            {ReportSearchType[TYPE.DAILY_HOUR]}
          </Select.Option>
        )}
        {tenMinutesSupport && (
          <Select.Option value={TYPE.TEN_MINUTES}>
            {ReportSearchType[TYPE.TEN_MINUTES]}
          </Select.Option>
        )}
      </Select>
      {value.reportType === TYPE.YEAR ? (
        <DatePicker
          allowClear={false}
          picker="year"
          value={moment(value.start)}
          placeholder={t('report:year')}
          format="YYYY"
          className="w-130"
          disabledDate={handleDisabledDate}
          onChange={handleChangeYM}
        />
      ) : value.reportType === TYPE.MONTH ? (
        <DatePicker
          allowClear={false}
          picker="month"
          value={moment(value.start)}
          placeholder={t('report:month')}
          format="YYYY/MM"
          className="w-130"
          disabledDate={handleDisabledDate}
          onChange={handleChangeYM}
        />
      ) : value.reportType === TYPE.TEN_MINUTES ||
        value.reportType === TYPE.DAILY_HOUR ? (
        <DatePicker
          allowClear={false}
          picker="date"
          value={moment(value.start)}
          placeholder={t('report:month')}
          format="YYYY/MM/DD"
          className="w-130"
          disabledDate={handleDisabledDate}
          onChange={handleChangeSingleDate}
        />
      ) : (
        <RangePicker
          allowClear={false}
          value={[value.start, value.end]}
          placeholder={[t('report:startDate'), t('report:endDate')]}
          format="YYYY.MM.DD"
          className="w-264"
          disabledDate={handleDisabledDate}
          onChange={handleChangeDate}
          onBlur={() => {
            setRangeDateType(null)
          }}
        />
      )}
    </div>
  )
}
