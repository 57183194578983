import { isNil, not } from 'ramda'
import React from 'react'
import { IInnmaxMapInstance } from './../map.types'

export const useInnmaxMapInstance = (map?: IInnmaxMapInstance) => {
  const mapInstance = React.useRef<IInnmaxMapInstance>()
  if (not(isNil(map))) {
    mapInstance.current = map
  }
  if (isNil(mapInstance.current)) {
    const proxyHandler = {}
    mapInstance.current = new Proxy(
      {
        selectedDevices: [],
        setSelectedDevices: () => {},
        setFocusDevice: () => {},
        proxyHandler,
      },
      proxyHandler
    ) as IInnmaxMapInstance
  }

  return [mapInstance.current]
}
