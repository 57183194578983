import { useAlarmTypes } from '@services/alarm'
import { Select, SelectProps } from '@shared/Form/Select'
import { compose, flatten, keys, mergeAll, pluck, values } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

type IAlarmDeviceTypeSelectProp = {
  showAll?: boolean
  others?: { value: string; name: string }[]
} & SelectProps

export default function AlarmDeviceTypeSelect({
  showAll = false,
  others = [],
  ...props
}: IAlarmDeviceTypeSelectProp) {
  const { t } = useTranslation()

  const { alarmTypes } = useAlarmTypes()

  const supportDeviceTypes = React.useMemo<string[]>(() => {
    return compose<any, any, any, any, any, any>(
      keys,
      mergeAll,
      values,
      flatten,
      pluck('deviceTypesVsCtrlerTypes')
    )(alarmTypes)
  }, [alarmTypes])

  return (
    <Select allowClear placeholder={t('report:select device')} {...props}>
      {showAll && (
        <Select.Option key="all" value="">
          {t('common:all')}
        </Select.Option>
      )}
      {supportDeviceTypes.map(x => (
        <Select.Option key={x} value={x}>
          {t(`device:select-${x}`)}
        </Select.Option>
      ))}
      {others.map(other => (
        <Select.Option key={other.value} value={other.value}>
          {other.name}
        </Select.Option>
      ))}
    </Select>
  )
}
