import { Device } from '@services/device'
import { LngLatLike, Map } from 'maplibre-gl'
import React from 'react'
import { DEFAULT_MAP_PLATFORM } from './map.options'
import { IMapPlatform, MapType } from './map.types'
import { InnmaxMapTool } from './tools'

type IInnmaxMapContext = {
  googleMap: google.maps.Map | null
  setGoogleMap: (map: google.maps.Map) => void
  maplibreMap: Map | null
  setMaplibreMap: (map: Map) => void
  defaultCenter: google.maps.LatLng | google.maps.LatLngLiteral | LngLatLike
  defaultZoom: number
  mapPlatform: IMapPlatform
  setMapPlatform: (platform: IMapPlatform) => void
  mapType?: MapType
  setMapType: (mapType: MapType) => void
  selectedDevices: Device[]
  setSelectedDevices: (devices: Device[]) => void
  focusDevice?: Device
  setFocusDevice: (device: Device | undefined) => void
}

export const InnmaxMapContext = React.createContext<IInnmaxMapContext>({
  googleMap: null,
  setGoogleMap: () => {},
  maplibreMap: null,
  setMaplibreMap: () => {},
  defaultCenter: {
    lat: 24.021789115561596,
    lng: 120.98170194610128,
  },
  defaultZoom: 10,
  mapPlatform: DEFAULT_MAP_PLATFORM,
  setMapPlatform: () => {},
  mapType: MapType.GoogleMap,
  setMapType: () => {},
  selectedDevices: [],
  setSelectedDevices: () => {},
  focusDevice: undefined,
  setFocusDevice: () => {},
})

export type IInnmaxMapProviderProps = {
  defaultCenter: google.maps.LatLng | google.maps.LatLngLiteral
  defaultZoom: number
  mapType?: MapType
}

export const InnmaxMapProvider = ({
  children,
  defaultCenter,
  defaultZoom,
  mapType: mapTypeProp,
}: React.PropsWithChildren<IInnmaxMapProviderProps>) => {
  const [googleMap, setGoogleMap] = React.useState<google.maps.Map | null>(null)
  const [maplibreMap, setMaplibreMap] = React.useState<Map | null>(null)
  const [mapPlatform, setMapPlatform] = React.useState<IMapPlatform>(
    InnmaxMapTool.getMapPlatform()
  )
  const [mapType, setMapType] = React.useState<MapType>(
    mapTypeProp || MapType.GoogleMap
  )
  const [selectedDevices, setSelectedDevices] = React.useState<Device[]>([])
  const [focusDevice, setFocusDevice] = React.useState<Device | undefined>()

  const contextValue = React.useMemo<IInnmaxMapContext>(() => {
    return {
      googleMap,
      setGoogleMap: (map: google.maps.Map) => {
        setGoogleMap(map)
      },
      maplibreMap,
      setMaplibreMap: (map: Map) => {
        setMaplibreMap(map)
      },
      defaultCenter,
      defaultZoom,
      mapPlatform,
      setMapPlatform,
      mapType,
      setMapType,
      selectedDevices,
      setSelectedDevices,
      focusDevice,
      setFocusDevice,
    }
  }, [
    googleMap,
    maplibreMap,
    mapPlatform,
    mapType,
    selectedDevices,
    focusDevice,
  ])

  return (
    <InnmaxMapContext.Provider value={contextValue}>
      {children}
    </InnmaxMapContext.Provider>
  )
}

export const useInnmaxMap = () => {
  const context = React.useContext(InnmaxMapContext)
  if (!context) {
    throw new Error('useInnmaxMap must be used within a InnmaxMapProvider')
  }
  return context
}
