import { InnmaxCluster, InnmaxMarker, InnternalInnmaxMapV2 } from './components'

type IInnternalInnmaxMap2 = typeof InnternalInnmaxMapV2 & {
  Marker: typeof InnmaxMarker
  Cluster: typeof InnmaxCluster
}

const InnmaxMap2: IInnternalInnmaxMap2 =
  InnternalInnmaxMapV2 as IInnternalInnmaxMap2
InnmaxMap2.Marker = InnmaxMarker
InnmaxMap2.Cluster = InnmaxCluster

export default InnmaxMap2

export * from './components'
export * from './hooks'
export * from './tools'
