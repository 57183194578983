import { Input } from 'antd'
import styled from 'styled-components'

const StyledInput = styled(Input)`
  &.ant-input-affix-wrapper {
    background-color: ${p => p.theme.input.bg};
    border-color: ${p => p.theme.input.borderColor};
    &:hover,
    &:focus {
      border-color: ${p => p.theme.input.hoverAndFocusBorderColor};
    }
    & svg[data-icon='close-circle'] {
      fill: ${p => p.theme.input.clearIconFill};
    }
    &.ant-input-affix-wrapper-disabled {
      background-color: ${p => p.theme.input.disabledBg};
    }
  }
  &.ant-input-affix-wrapper .ant-input,
  &.ant-input {
    background: ${p => p.theme.input.bg};
    border-radius: 4px;
    color: ${p => p.theme.input.color};
    border-color: ${p => p.theme.input.borderColor};
    &:hover,
    &:focus {
      border-color: ${p => p.theme.input.hoverAndFocusBorderColor};
    }

    &::placeholder {
      color: ${p => p.theme.input.placeholderColor};
    }

    &:disabled {
      background: ${p => p.theme.input.disabledBg} !important;
      color: ${p => p.theme.input.disabledColor} !important;
    }
  }

  &.ant-input-group-wrapper {
    &.custom-input-addon .ant-input-group-addon {
      background: ${p => p.theme.whisper};
      color: ${p => p.theme.darkGrey};
      font-weight: 500;
    }
  }

  &.sks-search {
    &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
      border-radius: 4px;

      .ant-input {
        font-size: 16px;
      }

      &:hover,
      &:focus {
        border-color: ${p => p.theme.input.hoverAndFocusBorderColor};
      }
      &:focus {
        box-shadow: none;
      }
    }

    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border-color: ${p => p.theme.input.hoverAndFocusBorderColor};
    }
  }

  &.placeholder-sm {
    .ant-input {
      ::placeholder {
        font-size: 13px;
      }
    }
  }
`

export default StyledInput
