import Box from '@shared/Box'
import theme from '@theme'
import { Col, Row, Spin } from 'antd'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import { isEmpty } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PAGE_SIZE, TYPE, dateTimeFormat } from '@services/report'
import ChartTitle from '../ChartTitle'
import type { ICommonProps } from '../type'
import Pagination from './../PaginationV2'
import { initChart, tooltipPointFormat } from './chartOption'

export function LinkRateChart(props: ICommonProps) {
  const { queryVariables, yAxisDateStr, dataSource, loading } = props
  const { t } = useTranslation()

  const chart = React.useRef<HighchartsReact.RefObject>(null)

  const [startIdx, setStartIdx] = React.useState(0)
  const query = React.useMemo(() => queryVariables, [queryVariables])
  const [options, setOptions] = React.useState(initChart)

  React.useEffect(() => {
    if (!loading) {
      chart?.current?.chart?.hideLoading()
      chart?.current?.chart?.setSize(null, null)
    } else {
      chart?.current?.chart?.showLoading()
    }
  }, [chart, loading])

  React.useEffect(() => {
    const endIdx = startIdx + PAGE_SIZE

    setOptions((x: any) => ({
      ...x,
      time: {
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      xAxis: {
        ...initChart.xAxis[0],
        title: {
          ...initChart.xAxis[0].title,
          text: query.reportType !== TYPE.DAY ? yAxisDateStr : t('report:days'),
        },
        type: 'datetime',
        labels: {
          style: {
            fontSize: '10px',
            color: theme.grey,
          },
          formatter: function (this: any) {
            return dateTimeFormat(query.reportType, this.value)
          },
        },
      },
      yAxis: [
        { ...initChart.yAxis[0] },
        {
          ...initChart.yAxis[1],
          title: {
            ...initChart.yAxis[1].title,
            text: '',
          },
        },
      ],
      tooltip: {
        ...initChart.tooltip,
        formatter: function (): any {
          const _this = this as any
          return (this as any).points.reduce(function (s: any, point: any) {
            return s + tooltipPointFormat(point, true, 'khw')
          }, moment(_this?.x).format('YYYY.MM.DD'))
        },
      },
      series: [
        {
          name: t('report:power'),
          type: 'column',
          data: dataSource?.chart?.slice(startIdx, endIdx),
        },
      ],
    }))
  }, [dataSource, startIdx]) // eslint-disable-line

  React.useEffect(() => {
    if (isEmpty(query)) {
      return
    }

    setStartIdx(0)
  }, [query]) // eslint-disable-line

  return (
    <>
      <Row gutter={[48, 48]} className="mr-40">
        <Col span={24}>
          <Pagination
            data={dataSource}
            className="my-40 ml-[65px]"
            pageSize={PAGE_SIZE}
            onChangePage={pg => {
              const idx = pg - 1
              setStartIdx(idx * PAGE_SIZE)
            }}
          />
        </Col>
        <Col span={24}>
          <Spin spinning={loading}>
            <Box position="relative">
              <Box
                position="absolute"
                top="-35px"
                left="25px"
                zIndex={1}
                fontSize="18px"
                className="font-medium text-report-color">
                kwh
              </Box>
              <div className="w-100 h-100 flex">
                <div className="flex flex-col justify-center text-report-color">
                  <div>耗</div>
                  <div>電</div>
                  <div>量</div>
                </div>
                <HighchartsReact
                  ref={chart}
                  containerProps={{ style: { width: '100%', height: '100%' } }}
                  highcharts={Highcharts}
                  options={options}
                />
              </div>
            </Box>
          </Spin>
        </Col>
        <ChartTitle {...props} />
      </Row>
    </>
  )
}

export default React.memo(LinkRateChart)
