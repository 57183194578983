import {
  IQueryProps,
  PAGE_SIZE,
  dateTimeFormat,
  dateTimeUnit,
  generateDatetime,
  useReportQuery,
} from '@services/report'
import { QueryVariables } from '@shared/innmaxLib/services'
import { isNil } from '@shared/utils/webHelper'
import moment from 'moment'
import { pathOr } from 'ramda'
import React from 'react'

export const usePowerChart = (
  querys: { [key: string]: any },
  options?: QueryVariables
) => {
  const { data, ...others } = useReportQuery(
    '/device/powersupplier/report/dailyTotalPower',
    querys,
    {
      enabled: !isNil(querys),
      ...options,
    }
  )

  const _dataSource = pathOr([], ['data'], data)

  const dataSource = React.useMemo(() => {
    const date: any = generateDatetime(
      querys.reportType,
      querys.start,
      querys.end
    )
    _dataSource?.forEach((item: any) => {
      const index: number = moment(item.date)
        .startOf(dateTimeUnit(querys.reportType))
        .valueOf()
      date[index][1] = date[index][1] + item.kWh
      item['displayDate'] = dateTimeFormat(querys.reportType, item.date)
    })

    return {
      content: _dataSource,
      totalPages: Math.ceil(Object.keys(date).length / PAGE_SIZE),
      chart: Object.keys(date)
        .map(k => date[k])
        .sort(),
    }
  }, [data, querys]) //eslint-disable-line

  return {
    dataSource,
    ...others,
  }
}

export const useExportPowerRpt = (
  query?: IQueryProps,
  options?: QueryVariables
) => {
  const [enabled, setIsEnabled] = React.useState(false)

  const { isLoading } = useReportQuery(
    '/device/powersupplier/report/dailyTotalPower/export',
    { deep: true, ...query, pageNum: undefined, pageSize: undefined },
    {
      ...options,
      enabled,
      onSuccess: (response: any) => {
        if (options?.onSuccess) {
          options.onSuccess(response)
        }
        setIsEnabled(false)
      },
      onError: error => {
        console.log(error)
        setIsEnabled(false)
      },
    }
  )

  return [
    isLoading,
    () => {
      setIsEnabled(true)
    },
  ] as [boolean, () => void]
}
