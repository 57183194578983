import { useDeviceCtrlerType } from '@services/device'
import { Select, SelectProps } from '@shared/Form/Select'
import { useTranslation } from 'react-i18next'

export const DeviceCtrlerTypeSelect = (
  props: SelectProps & { deviceType: string }
) => {
  const { onChange, value, deviceType, ...others } = props

  const { t } = useTranslation()

  const { dataSource } = useDeviceCtrlerType({ deviceType })

  return (
    <Select
      allowClear
      placeholder={t('device:selectCtrlerType')}
      onChange={(type, option) => {
        onChange && onChange(type, option)
      }}
      value={value}
      {...others}>
      {dataSource.map(d => (
        <Select.Option key={d.ctrlerType} value={d.ctrlerType}>
          {d.ctrlerType}
        </Select.Option>
      ))}
    </Select>
  )
}

export default DeviceCtrlerTypeSelect
