import { Device } from '@services/device'
import maplibregl, { LngLat, Marker } from 'maplibre-gl'
import React from 'react'
import ReactDOM from 'react-dom/client'
import useSupercluster from 'use-supercluster'
import { MapType } from '../../map.types'
import { useMapLibre } from '../Provider'

interface IMarkerClusterProps {
  pointItems?: Device[]
  onRenderMarkerCluster?: (pointItems: Device[]) => React.ReactNode
  children?: (pointItems: Device[]) => React.ReactNode
}

const MarkerCluster = React.memo((props: IMarkerClusterProps) => {
  const { pointItems = [], onRenderMarkerCluster, children } = props

  const { map, projectRatioPointsToLngLat, mapType } = useMapLibre()

  const [zoom, setZoom] = React.useState(map?.getZoom() || 15)

  const [normalPointItems, setNormalPointItems] = React.useState<Device[]>([])

  const { clusters, supercluster } = useSupercluster({
    zoom,
    points: pointItems.map(pointItem => {
      let coordinates = [0, 0]
      if (mapType === MapType.GoogleMap && pointItem?.lon && pointItem?.lat) {
        coordinates = [pointItem.lon, pointItem.lat]
      }
      if (mapType === MapType.Upload && pointItem?.x && pointItem?.y) {
        const lngLat = projectRatioPointsToLngLat({
          x: pointItem.x,
          y: pointItem.y,
        }) as LngLat
        coordinates = Array.isArray(lngLat) ? lngLat : lngLat.toArray()
      }

      return {
        type: 'Feature',
        properties: {
          cluster: false,
          payload: pointItem,
        },
        geometry: {
          type: 'Point',
          coordinates,
        },
      }
    }) as any,
    options: {
      radius: 40,
      maxZoom: 25,
    },
    bounds: map?.getBounds().toArray().flat() as any,
  })

  React.useEffect(() => {
    if (!map) {
      return
    }
    const handleZoom = () => {
      setZoom(map?.getZoom())
    }
    map.on('zoom', handleZoom)
    return () => {
      map.off('zoom', handleZoom)
    }
  }, [map])

  React.useEffect(() => {
    if (!map) {
      return
    }

    const getClusterChildrenPayloadRecursion = (
      cluster: any,
      payloadsOut: Device[] = []
    ) => {
      const { cluster: isCluster } = cluster.properties
      if (isCluster) {
        const children = supercluster?.getChildren(cluster.id as any) || []
        children.forEach(clusterChild => {
          getClusterChildrenPayloadRecursion(clusterChild, payloadsOut)
        })
      } else {
        payloadsOut.push(cluster)
      }
    }

    const _markers: Marker[] = []
    const _normalPointItems: Device[] = []
    clusters.forEach(cluster => {
      const [longitude, latitude] = cluster.geometry.coordinates
      const { cluster: isCluster } = cluster.properties

      if (isCluster) {
        if (!onRenderMarkerCluster) {
          return
        }
        const payloads: Device[] = []
        getClusterChildrenPayloadRecursion(cluster, payloads)

        const renderContent = onRenderMarkerCluster(payloads)

        const root = document.createElement('div')
        root.style.cursor = 'pointer'
        root.style.zIndex = '2000'
        ReactDOM.createRoot(root).render(renderContent)
        const marker = new maplibregl.Marker({
          element: root,
        })
        marker.setLngLat([longitude, latitude]).addTo(map)
        root.addEventListener('click', e => {
          if (e.button !== 0) {
            return
          }
          const zoomLevel = supercluster?.getClusterExpansionZoom(
            cluster.id as any
          )
          map.flyTo({ zoom: zoomLevel, center: [longitude, latitude] })
        })
        _markers.push(marker)
      } else {
        _normalPointItems.push(cluster.properties.payload)
      }
    })
    setNormalPointItems(_normalPointItems)
    return () => {
      _markers.forEach(m => m.remove())
    }
  }, [JSON.stringify(clusters), map])

  const childrenMarkers = React.useMemo(() => {
    return children && children(normalPointItems)
  }, [normalPointItems, children])

  return <>{childrenMarkers}</>
})

export default MarkerCluster
