import { envStore } from '@shared/env'
import { isNil } from 'ramda'
import React from 'react'

const useWebVersionChecker = () => {
  const [hasNewVersion, setHasNewVersion] = React.useState<boolean>(false)

  React.useEffect(() => {
    const fetchEnv = async () => {
      try {
        const appBaseName = envStore.appBaseName.replace(/\/$/, '')
        const response = await fetch(
          `${appBaseName}/env.json?cache=${Date.now()}`
        )
        const data = await response.json()

        const { webVersion } = data
        const currentVersion = (window as any).webVersion

        setHasNewVersion(
          isNil(webVersion) ? false : webVersion !== currentVersion
        )
      } catch (error) {
        console.error('Failed to check for updates', error)
      }
    }
    fetchEnv()
    const intervalId = setInterval(fetchEnv, 60000)
    return () => clearInterval(intervalId)
  }, [])

  return {
    hasNewVersion,
  }
}

export default useWebVersionChecker
