import { Table } from 'antd'
import { TableProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'

const StyledTable: any = styled(Table)`
  &.ant-table-wrapper {
    .ant-table table {
      border-spacing: 0 ${p => p.theme.table.borderSpacing}px;
    }

    &.pagination--none .ant-table-pagination {
      display: none;
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border-color: ${p => p.theme.pagination.borderColor};
      border-radius: 6px;
      color: ${p => p.theme.pagination.color};
    }

    .ant-pagination-item {
      border-color: ${p => p.theme.pagination.borderColor};
    }

    .ant-pagination-item a {
      color: ${p => p.theme.pagination.color};
    }

    .ant-pagination-item-active,
    .ant-pagination-item-active:focus,
    .ant-pagination-item:hover {
      border-color: ${p => p.theme.pagination.borderColor};
      background: ${p => p.theme.pagination.bg};

      a {
        color: #fff;
      }
    }

    .ant-table-pagination.ant-pagination {
      margin-top: 32px;
    }

    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: ${p => p.theme.pagination.hover.borderColor};
      background: ${p => p.theme.pagination.hover.bg};
      color: ${p => p.theme.pagination.hover.color};
    }

    .ant-pagination-simple-pager {
      color: ${p => p.theme.pagination.color};
    }
  }

  .ant-table-thead {
    line-height: 1em;

    > tr > th {
      font-size: 13px;
      font-weight: normal;
      color: ${p => p.theme.table.head.color};
      background: ${p => p.theme.table.head.bg};
      border-color: transparent transparent
        ${p => p.theme.table.head.separatorColor};
    }
  }

  .ant-table-tbody {
    > tr {
      > td {
        height: 48px;
        padding-top: 7px;
        padding-bottom: 7px;
        line-height: 1em;
        background: ${p => p.theme.table.cell.oddBg};
        color: ${p => p.theme.table.cell.color};
        border-top: 1px solid transparent;
        border-bottom: ${p => p.theme.table.cell.borderBottom};
      }
      &:nth-child(even) {
        > td {
          background: ${p => p.theme.table.cell.evenBg};
        }
      }
    }
  }

  .ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
      .ant-table-row-selected
    )
    > td {
    background: ${p => p.theme.table.cell.bgHover};
  }

  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: ${p => p.theme.table.cell.bgHover};
  }

  .ant-table-tbody > tr.ant-table-row-selected td {
    background: ${p => p.theme.table.cell.selectedBg};
  }

  .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${p => p.theme.checkbox.border};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${p => p.theme.checkbox.bg};
    border-color: ${p => p.theme.checkbox.border};
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid ${p => p.theme.checkbox.checkedColor};
    border-top: 0;
    border-left: 0;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${p => p.theme.checkbox.indeterminate};
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 0 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
  }

  .ant-form-explain {
    font-size: 13px;
  }

  .has-error .ant-form-explain {
    position: absolute !important;
    font-size: 12px !important;
  }
`

class TableComponent<T extends any> extends React.Component<TableProps<T>> {
  static Column = Table.Column
  render() {
    return <StyledTable {...this.props}>{this.props.children}</StyledTable>
  }
}

export default TableComponent
