import React from 'react'
import Marker, { IEnhanceMarkerRef } from './Marker'
import { Device } from '@services/device'
import cx from 'classnames'
import { DeviceStatus } from '@services/constants'
import { css } from 'styled-components'
import { markerIcon } from '@shared/innmaxMap/components/Marker'
import { IDeviceMarkerProps } from '@shared/InnmaxMap2/map.types'

const DeviceMarker = React.memo((props: IDeviceMarkerProps) => {
  const { item, focus, selected, onDelete, onDragEnd, onLeftClick, ...others } =
    props

  const markerRef = React.useRef<IEnhanceMarkerRef>(null)

  const className = cx(
    item?.deviceType,
    item?.deviceStatus === DeviceStatus.ALARM
      ? 'alarm'
      : item?.deviceStatus === DeviceStatus.REPAIR
      ? 'repair'
      : 'normal',
    item?.subDeviceCount
      ? item?.isSubAlarm
        ? 'has-child-alarm'
        : 'has-child'
      : '',
    item?.brightnessType,
    { selected: selected },
    { 'icon-animation': focus },
    'marker-wrapper'
  )

  React.useEffect(() => {
    markerRef.current?.setPosition({
      lat: item?.lat,
      lon: item?.lon,
      x: item?.x,
      y: item?.y,
    })
  }, [item])

  return Marker.Utils.isValidMarkerPosition(item) ? (
    <Marker
      {...others}
      ref={markerRef}
      tooltipContent={
        <span>{item?.displayName || item?.deviceDisplayName}</span>
      }
      onDelete={() => item && onDelete && onDelete(item)}
      onLeftClick={() => {
        item && onLeftClick && onLeftClick(item)
      }}
      defaultPosition={{
        lat: item?.lat,
        lon: item?.lon,
        x: item?.x,
        y: item?.y,
      }}
      onDragEnd={position => {
        onDragEnd &&
          onDragEnd({
            ...item,
            ...position,
          } as Device)
      }}>
      <Marker.MarkerIconDiv>
        <div
          className={className}
          css={css`
            width: 24px;
            height: 24px;
          `}
          data-device-status={
            item?.subDeviceCount && item?.isSubAlarm
              ? DeviceStatus.ALARM + ''
              : item?.deviceStatus + ''
          }>
          <img
            css={css`
              padding: 3px;
            `}
            src={markerIcon(item)}
            alt={item.deviceName}
          />
        </div>
      </Marker.MarkerIconDiv>
    </Marker>
  ) : (
    <div></div>
  )
})

export default DeviceMarker
