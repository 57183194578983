import {
  Marker as MarkerComponent,
  InfoWindow as MarkerInfoWindow,
  OverlayView,
  OverlayViewF,
  OverlayViewProps,
} from '@react-google-maps/api'
import { Clusterer } from '@react-google-maps/marker-clusterer'
import { DeviceStatus } from '@services/constants'
import { Device } from '@services/device'
import { imageUrlPrefix } from '@shared/env'
import useVisible from '@shared/innmaxLib/hooks/useVisible'
import cx from 'classnames'
import React from 'react'
import styled from 'styled-components'
import { IDeviceMarkerProps } from '../map.types'
import { useGoogleMap } from './Provider'
import { DeleteWindow } from './Window'

enum MarkerImageType {
  PNG = '.png',
  SVG = '.svg',
}

function markerIcon(
  d: Device,
  imageType: MarkerImageType = MarkerImageType.PNG
) {
  const status = d?.isRepair ? 'repair' : d?.isAlarm ? 'alarm' : 'normal'

  return `${imageUrlPrefix}/marker/${d?.deviceType}/normal${imageType}#${d?.deviceType}-${d?.id}-${status}`
}

interface MarkerComponentProps
  extends Omit<OverlayViewProps, 'position' | 'mapPaneName'> {
  item: Device
  focus?: boolean
  tooltip?: boolean
  enableClusterer?: boolean
  deletable?: boolean
  draggable?: boolean
  selected?: boolean

  clusterer?: Clusterer
  onLeftClick?: (d: Device) => void
  onRightClick?: (d: Device) => void
  onMouseOver?: (d: Device) => void
  onMouseOut?: (d: Device) => void
  onDrag?: (e: google.maps.MapMouseEvent) => void
  onDragEnd?: (e: Device) => void
  onDelete?: (d: Device) => void
}

export default function DeviceMarker({
  children,
  item,
  focus,
  tooltip = true,
  clusterer,
  // enableClusterer = true,
  deletable = false,
  draggable = false,
  selected = false,

  onLeftClick,
  onRightClick,
  onMouseOver,
  onMouseOut,
  onDrag,
  onDragEnd,
  onDelete,
  ...others
}: IDeviceMarkerProps) {
  const markerRef = React.useRef<any>()

  const { isStreetMode } = useGoogleMap()

  const [markerPosition, setMarkerPosition] = React.useState<
    google.maps.LatLng | google.maps.LatLngLiteral
  >()

  const tooltipWindow = useVisible()

  const deleteWindow = useVisible()

  const markerVisible = useVisible()

  const icon = item?.deviceType && markerIcon(item)

  const className = React.useMemo(
    () =>
      cx(
        item?.deviceType,
        item?.deviceStatus === DeviceStatus.ALARM
          ? 'alarm'
          : item?.deviceStatus === DeviceStatus.REPAIR
          ? 'repair'
          : 'normal',
        item?.subDeviceCount
          ? item?.isSubAlarm
            ? 'has-child-alarm'
            : 'has-child'
          : '',
        item?.brightnessType,
        { selected: selected },
        { 'icon-animation': focus },
        { hidden: clusterer && !markerVisible.visible },
        'marker-wrapper'
      ),
    [item, selected, focus, markerVisible.visible, clusterer]
  ) //eslint-disable-line

  const handleLeftClick = React.useCallback(
    (e: any) => {
      item && onLeftClick && onLeftClick(item)
    },
    [onLeftClick]
  ) //eslint-disable-line

  const handleRightClick = React.useCallback(
    (e: any) => {
      item && onRightClick && onRightClick()

      if (deletable) {
        deleteWindow.toggle()
      }
    },
    [onRightClick]
  ) //eslint-disable-line

  const handleMouseOver = React.useCallback((e: any) => {
    tooltipWindow.open()
  }, []) //eslint-disable-line

  const handleMouseOut = React.useCallback((e: any) => {
    tooltipWindow.close()
  }, []) //eslint-disable-line

  const handleOnDrag = React.useCallback(
    (e: google.maps.MapMouseEvent) => {
      setMarkerPosition(e.latLng?.toJSON())
      onDrag && onDrag()
    },
    [onDrag]
  ) //eslint-disable-line

  const handleOnDragEnd = React.useCallback(
    (e: google.maps.MapMouseEvent) => {
      setMarkerPosition(e.latLng?.toJSON())
      onDragEnd &&
        onDragEnd({
          ...item,
          lat: e?.latLng?.lat() || 0,
          lon: e?.latLng?.lng() || 0,
        } as Device)
    },
    [onDragEnd]
  ) //eslint-disable-line

  const handleOnMarkerVisibleChanged = React.useCallback(() => {
    if (markerRef) {
      markerRef?.current?.marker?.visible
        ? markerVisible.open()
        : markerVisible.close()
    }
    tooltipWindow.close()
  }, [markerRef]) //eslint-disable-line

  React.useEffect(() => {
    if (item?.lat && item?.lon) {
      setMarkerPosition({
        lat: item?.lat,
        lng: item?.lon,
      })
    }
  }, [item])

  //   React.useEffect(() => {
  //     // 狀態切換時，有些marker已存在的情況下，會被隱藏
  //     setTimeout(() => {
  //       clusterer?.redraw()
  //     }, 300)
  //   }, [clusterer?.clusters]) //eslint-disable-line

  React.useEffect(() => {
    // 關閉刪除window
    document.addEventListener('click', deleteWindow.close)
    return () => document.removeEventListener('click', deleteWindow.close)
  }, []) //eslint-disable-line

  if (!markerPosition?.lat || !markerPosition?.lng) {
    return <div />
  }

  return (
    <MarkerComponent
      icon={isStreetMode ? undefined : icon}
      ref={markerRef}
      title={isStreetMode ? item?.displayName || '' : ''}
      position={markerPosition as google.maps.LatLng}
      clusterer={clusterer}
      animation={focus ? google.maps.Animation.BOUNCE : undefined}
      onDrag={handleOnDrag}
      onDragEnd={handleOnDragEnd}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleLeftClick}
      onRightClick={handleRightClick}
      onVisibleChanged={handleOnMarkerVisibleChanged}
      zIndex={50}
      opacity={isStreetMode ? 1 : 0}
      draggable={draggable}>
      <OverlayViewF
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        position={markerPosition as google.maps.LatLng}
        {...others}>
        <>
          {tooltip && tooltipWindow.visible && item?.displayName && (
            <MarkerInfoWindow
              position={markerPosition}
              options={{
                ...infoboxOptions,
                pixelOffset: new google.maps.Size(-1, -25),
              }}>
              <StyledTooltipWrapper>
                <StyledTootipContent className="notranslate">
                  {item?.displayName}
                </StyledTootipContent>
                <StyledTooltipArrow></StyledTooltipArrow>
              </StyledTooltipWrapper>
            </MarkerInfoWindow>
          )}
          {deleteWindow.visible && (
            <MarkerInfoWindow
              position={markerPosition}
              options={{
                ...infoboxOptions,
                pixelOffset: new google.maps.Size(100, 13),
              }}>
              <DeleteWindow
                data={item}
                onCancel={deleteWindow.toggle}
                deleteMarker={() => item && onDelete && onDelete(item)}
              />
            </MarkerInfoWindow>
          )}
          <StyledMarkerWrapper className={className}>
            <img src={icon} alt="" />
          </StyledMarkerWrapper>
        </>
      </OverlayViewF>
    </MarkerComponent>
  )
}

const StyledMarkerWrapper = styled.div`
  translate: -13px -30px;
`

const StyledTooltipWrapper = styled.div`
  position: relative;
`

const StyledTootipContent = styled.div`
  background-color: ${p => p.theme.yellow100};
  border-radius: 2px;
  font-size: 13px;
  font-weight: normal;
  padding: 3px 8px;
  min-width: 68px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
`

const StyledTooltipArrow = styled.div`
  background-color: transparent;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid ${p => p.theme.yellow100};
`

const infoboxOptions = {
  visible: true,
  alignBottom: false,
  disableAutoPan: false,
  enableEventPropagation: false,
  boxClass: 'style-info-box',
  boxStyle: {
    overflow: 'hidden',
  },
}
