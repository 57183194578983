import theme from '@theme'
import moment from 'moment'

export const tooltipPointFormat = (
  point: any,
  isLineChart: any,
  unit = '次'
) => {
  return (
    `<div style="margin-top:4px; font-size:12px;font-weight:500;color:${theme.tooltip.color};">` +
    `${point.series.name} <span style="display:inline-block;">${point.y} ${unit}</span>` +
    `<span style="display:inline-block; background-color:${point.color}; ${
      point.colorIndex === 1 && isLineChart
        ? 'width:8px; height:12px;'
        : 'width:8px; height:12px;'
    }  margin-left:8px;"></span>` +
    '</div>'
  )
}

export const initChart = {
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  colors: [theme.n.blue250, '#c0973f', theme.primary],
  chart: {
    type: 'column',
    height: 320,
    backgroundColor: 'transparent',
  },
  title: {
    text: '',
  },
  xAxis: {
    labels: {
      style: {
        color: theme.tooltip.color,
        fontSize: '13px;',
      },
    },
    title: {
      align: 'high',
      style: {
        color: '#4a4a4a',
        fontSize: 18,
      },
    },
  },
  yAxis: {
    labels: {
      style: {
        color: theme.tooltip.color,
        fontSize: '14px;',
      },
    },
    title: {
      margin: 0,
      style: {
        color: theme.tooltip.color,
      },
      useHTML: true,
      text: '',
    },
  },
  tooltip: {
    shared: true,
    useHTML: true,
    backgroundColor: theme.tooltip.bg,
    borderRadius: 4,
    formatter() {
      const _this = this as any
      return [''].concat(
        _this.points
          ? _this.points.map(function (val: any) {
              return (
                `<b style="font-size:13px; color:${theme.tooltip.color};font-weight:500"><div style="margin-bottom:8px;">` +
                moment(val.point?.category).format('YYYY.MM.DD') +
                '</div></b>' +
                tooltipPointFormat(val, false)
              )
            })
          : []
      )
    },
  },
  plotOptions: {
    column: {
      pointWidth: 10,
      state: { hover: { brightness: 0 } },
      // pointPadding: 2,
      // borderWidth: 0,
    },
  },
}
