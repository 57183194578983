import { TYPE } from '@services/report'
import { getRangeDate } from '@shared/utils/webHelper'
import { FormInstance } from 'antd/lib/form'
import { TablePaginationConfig } from 'antd/lib/table'
import type {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/lib/table/interface'
import moment from 'moment'
import { assocPath, identity, ifElse, is, mergeDeepRight, of } from 'ramda'
import React from 'react'

export type TableChangeHandler<T = any> = (
  pagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: SorterResult<T> | SorterResult<T>[],
  extra: TableCurrentDataSource<T>
) => void

export type UseSearchProps = {
  form?: FormInstance
  initial?: { [key: string]: any }
  dateRangeField?: [string, string]
  dateFormat?: string
  transform?: (values: any) => any
  keepPreviousQuery?: boolean
}

export default function useSearch<T = any>({
  form,
  initial = {
    pageSize: 10,
  },
  dateFormat,
  dateRangeField = ['start', 'end'],
  transform = values => values,
  keepPreviousQuery = true,
}: UseSearchProps = {}) {
  const pageSize = initial.pageSize || 10

  const [query, setQuery] = React.useState<any>({
    ...initial,
    pageNum: 0,
    pageSize,
  })

  const [pageNum, setPageNum] = React.useState(1)
  const [isTriggerSearch, setIsTriggerSearch] = React.useState(false)

  const handleChange =
    <T = any>(field: string | string[]) =>
    (value: T, converter: (value: T) => any = (value: T) => value) => {
      const _field = ifElse(is(Array), identity, of)(field)

      setQuery(assocPath(_field, converter(value), query))
    }

  const handleSearch = () => {
    if (!form) {
      return
    }
    setIsTriggerSearch(true)
    const { date = [], reportTime, ...values } = form.getFieldsValue()
    const [startDate, endDate] = getRangeDate(date, dateFormat)
    const [startField, endField] = dateRangeField

    setPageNum(1)
    setQuery((prev: any) => {
      const reportTimeQuery =
        reportTime?.reportType === TYPE.YEAR
          ? {
              [startField]: moment()
                .year(reportTime.conditionDate)
                .startOf('year')
                .valueOf(),
              [endField]: moment()
                .year(reportTime.conditionDate)
                .endOf('year')
                .valueOf(),
            }
          : reportTime?.reportType === TYPE.MONTH
          ? {
              [startField]: moment(reportTime.conditionDate)
                .startOf('month')
                .valueOf(),
              [endField]: moment(reportTime.conditionDate)
                .endOf('month')
                .valueOf(),
            }
          : reportTime?.reportType === TYPE.DAY
          ? {
              [startField]: reportTime?.start?.startOf('day').valueOf(),
              [endField]: reportTime?.end?.endOf('day').valueOf(),
            }
          : {}
      const q = mergeDeepRight(
        {
          ...(keepPreviousQuery && prev),
          ...values,
          ...reportTime,
          pageNum: 0,
          pageSize,
          [startField]: startDate,
          [endField]: endDate,
          reactQueryCache: new Date().getTime(),
        },
        reportTimeQuery
      )

      if (transform) {
        return transform(q)
      }
      return q
    })
  }

  const handleTableChange: TableChangeHandler<T> = ({ current, pageSize }) => {
    if (!!current) {
      setPageNum(current)
      setQuery((prev: any) => {
        const q = {
          ...prev,
          current: Number(current) - 1,
          size: pageSize,
          pageNum: Number(current) - 1,
        }

        return q
      })
    }
  }

  return {
    query,
    pageNum,
    setQuery,
    setPageNum,
    handleChange,
    handleSearch,
    handleTableChange,
    isTriggerSearch,
  }
}
