import Box from '@shared/Box'
import theme from '@theme'
import { Col, Row, Spin } from 'antd'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { isEmpty } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PAGE_SIZE, TYPE, dateTimeFormat } from '@services/report'
import ChartTitle from '../ChartTitle'
import type { ICommonProps } from '../type'
import Pagination from './../PaginationV2'
import { initChart } from './chartOption'

export function AlarmBarChart(props: ICommonProps) {
  const { dataSource, loading, queryVariables, yAxisDateStr } = props

  const { t } = useTranslation()

  const chart = React.useRef<HighchartsReact.RefObject>(null)

  const [startIdx, setStartIdx] = React.useState(0)
  const query = React.useMemo(() => queryVariables, [queryVariables])

  const [options, setOptions] = React.useState(initChart)

  React.useEffect(() => {
    if (!loading) {
      chart?.current?.chart?.hideLoading()
      chart?.current?.chart?.setSize(null, null)
    } else {
      chart?.current?.chart?.showLoading()
    }
  }, [chart, loading])

  React.useEffect(() => {
    const endIdx = startIdx + PAGE_SIZE

    setOptions(x => ({
      ...x,
      time: {
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      xAxis: {
        ...initChart.xAxis,
        type: 'datetime',
        labels: {
          ...initChart.xAxis.labels,
          style: {
            fontSize: '10px',
            color: theme.grey,
          },
          formatter: function (this: any) {
            return dateTimeFormat(query?.reportType, this.value)
          },
        },
        title: {
          ...initChart.xAxis.title,
          text: query.reportType !== TYPE.DAY ? yAxisDateStr : t('report:days'),
        },
      },
      yAxis: {
        ...initChart.yAxis,
      },
      series: [
        {
          name: t('report:total number of alarm'),
          type: 'column',
          data: dataSource?.chart?.slice(startIdx, endIdx),
        },
      ],
    }))
  }, [dataSource, startIdx])

  React.useEffect(() => {
    if (isEmpty(query)) {
      return
    }

    setStartIdx(0)
  }, [query]) // eslint-disable-line

  return (
    <Row gutter={[48, 48]} className="mr-40">
      <Col span={24}>
        <Pagination
          data={dataSource}
          className="my-40 ml-[65px]"
          pageSize={PAGE_SIZE}
          onChangePage={pg => {
            const idx = pg - 1
            setStartIdx(idx * PAGE_SIZE)
          }}
        />
      </Col>
      <Col span={24}>
        <Spin spinning={loading}>
          <Box position="relative">
            <Box
              position="absolute"
              top="-35px"
              left="25px"
              zIndex={1}
              fontSize="18px"
              className="font-medium text-report-color">
              {t('report:count')}
            </Box>
            <div className="w-100 h-100 flex">
              <div className="flex flex-col justify-center text-report-color">
                <div>警</div>
                <div>報</div>
                <div>數</div>
              </div>
              <HighchartsReact
                ref={chart}
                containerProps={{
                  style: { width: '100%', height: '100%' },
                }}
                highcharts={Highcharts}
                options={options}
              />
            </div>
          </Box>
        </Spin>
      </Col>
      <ChartTitle {...props} />
    </Row>
  )
}

export default React.memo(AlarmBarChart)
