import { Button } from '@shared/Form'
import useSearch from '@shared/innmaxLib/hooks/useSearch'
import Box from '@shared/innmaxUI/Box'
import { downloadFile } from '@shared/utils/webHelper'
import { Form } from 'antd'
import cx from 'classnames'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { DeviceType } from '@services/constants'
import {
  TAB,
  TYPE,
  useExportLinkRateRpt,
  useLinkRateChart,
} from '@services/report'
import { useAllSessions } from '@services/session'
import { FormItem } from 'src/components/Form'
import OptionsSelect from 'src/components/OptionsSelect'
import { css } from 'styled-components'
import { TabContainer, TabPane } from '../style'
import { ICommonProps } from '../type'
import LinkRateChart from './LinkRateChart'
import LinkRateReport from './LinkRateReport'

const initialReportType = {
  deviceType: DeviceType.LIGHT,
  reportType: TYPE.YEAR,
  conditionDate: new Date().getFullYear(),
  start: moment().year(new Date().getFullYear()).startOf('year').valueOf(),
  end: moment().year(new Date().getFullYear()).endOf('year').valueOf(),
}

function LinkRate() {
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const [currentTab, setCurrentTab] = React.useState(TAB.BAR_CHART)
  const [currentType, setCurrentType] = React.useState(TYPE.YEAR)

  const { byId } = useAllSessions()

  const { query, handleSearch } = useSearch({
    form,
    initial: initialReportType,
    transform: values => {
      const params = {
        ...values,
        deviceType: DeviceType.LIGHT,
      }

      return params
    },
  })

  const { dataSource, isLoading } = useLinkRateChart(query)

  const [exporting, handleExport] = useExportLinkRateRpt(query, {
    onSuccess: (blob: any) => {
      downloadFile({
        blob,
        filename: 'report',
      })
    },
  })

  const handleTab = (target: number) => () => {
    setCurrentTab(target)
  }

  const handleReportType = (val: any) => {
    setCurrentType(val?.reportType)
    return val
  }

  const yAxisDateStr = React.useMemo(() => {
    switch (query.reportType) {
      case TYPE.YEAR:
        return t('report:year', { num: query.conditionDate })
      case TYPE.MONTH:
        return t('report:month', { num: query.conditionDate.split('/')[1] }) //YYYY/MM
      case TYPE.DAY:
        const startDate = moment(query.start)
          .startOf('day')
          .format('YYYY.MM.DD')
        const endDate = moment(query.end).endOf('day').format('YYYY.MM.DD')
        return `${startDate} - ${endDate} `
      default:
        return ''
    }
  }, [query])

  const sessionName = React.useMemo(
    () => query?.sessionId && byId[query.sessionId].name,
    [query]
  )

  const props: ICommonProps = React.useMemo(
    () => ({
      queryVariables: { ...query, deviceType: query.deviceType },
      yAxisDateStr,
      yAxisName: '連線率',
      sessionName,
      dataSource,
      loading: isLoading,
    }),
    [query, yAxisDateStr, dataSource, isLoading, sessionName]
  )

  return (
    <div
      className="mx-24"
      css={css`
        height: calc(100vh - 112px);
        display: flex;
        flex-direction: column;
        overflow: auto !important;
      `}>
      <Box
        bg="#fff"
        borderRadius={8}
        mb={24}
        boxShadow="0 2px 10px 0 rgba(0,0,0,0.1)"
        padding="24px 32px 32px">
        <Form layout="inline" form={form}>
          <FormItem label={t('report:area')} name="sessionId" className="w-144">
            <OptionsSelect.SessionSelect />
          </FormItem>
          <FormItem
            label={`${t('report:report type')} / ${t('report:time')}`}
            name="reportTime"
            initialValue={initialReportType}
            className={currentType === TYPE.DAY ? 'w-[380px]' : 'w-[250px]'}
            getValueFromEvent={handleReportType}>
            <OptionsSelect.ReportRangeTimeSelect />
          </FormItem>
          <FormItem.Button>
            <Button
              loading={isLoading}
              type="primary"
              className="w-96"
              onClick={handleSearch}>
              {t('common:search')}
            </Button>
          </FormItem.Button>
          <FormItem.Button>
            <Button
              loading={exporting}
              type="primary"
              className="w-96"
              ghost
              onClick={handleExport}>
              {t('report:export')}
            </Button>
          </FormItem.Button>
        </Form>
      </Box>
      <TabContainer style={{ minHeight: 'unset', flex: 1 }}>
        <TabPane>
          <div
            onClick={handleTab(TAB.BAR_CHART)}
            className={cx('tab__item', {
              'is--active': currentTab === TAB.BAR_CHART,
            })}>
            {t('report:chart mode')}
          </div>
          <div
            onClick={handleTab(TAB.LIST)}
            className={cx('tab__item', {
              'is--active': currentTab === TAB.LIST,
            })}>
            {t('report:report mode')}
          </div>
        </TabPane>
        {currentTab === TAB.LIST && <LinkRateReport {...props} />}
        {currentTab === TAB.BAR_CHART && <LinkRateChart {...props} />}
      </TabContainer>
    </div>
  )
}

export default React.memo(LinkRate)
