import Box from '@shared/Box'
import theme from '@theme'
import { Col, Row, Spin } from 'antd'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import { compose, groupBy, isEmpty, map, prop, values } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PAGE_SIZE, TYPE } from '@services/report'
import ChartTitle from '../ChartTitle'
import Pagination from '../Pagination'
import { initAlarmBarChart, tooltipPointFormat } from '../ReportChartConfig'
import type { ICommonProps } from '../type'

export function MaintenanceChart(props: ICommonProps) {
  const { queryVariables, yAxisDateStr, dataSource, loading } = props
  const { t } = useTranslation()

  const [startIdx, setStartIdx] = React.useState(0)
  const [currentChart, setCurrentChart] = React.useState(0)
  const [query] = React.useState(queryVariables)
  const [options, setOptions] = React.useState<any>(initAlarmBarChart)

  const chart = React.useRef<HighchartsReact.RefObject>(null)

  const [data, setData]: any = React.useState([])

  React.useEffect(() => {
    const unit = queryVariables.reportType === TYPE.YEAR ? 'month' : 'day'

    let date: any = {}
    for (
      let i = queryVariables?.createEndAt;
      moment(i)?.diff(queryVariables?.createStartAt, unit) >= 0;
      i = moment(i).add(-1, unit)?.startOf(unit)
    ) {
      date[moment(i).valueOf()] = [moment(i).valueOf(), 0]
    }

    setData(
      [
        ...Object.keys(date)
          .filter((i: any) => {
            return !compose(
              map((i: any) => moment(i[0].createAtStr).startOf(unit).valueOf()),
              values,
              groupBy<any>(prop('createAt')),
              map((i: any) => ({
                ...i,
                createAt: moment(i.createAt).startOf(unit),
              }))
            )(dataSource.content).includes(Number(i))
          })
          .map(i => [Number(i), 0]),
        ...compose(
          map((i: any) => [
            moment(i[0].createAtStr).startOf(unit).valueOf(),
            i.length,
          ]),
          values,
          groupBy<any>(prop('createAt')),
          map((i: any) => ({
            ...i,
            createAt: moment(i.createAt).startOf(unit),
          }))
        )(dataSource.content),
      ].sort()
    )
  }, [dataSource])

  React.useEffect(() => {
    const endIdx = startIdx + PAGE_SIZE

    const unit = queryVariables.reportType === TYPE.YEAR ? 'month' : 'day'

    let date: any = {}
    for (
      let i = queryVariables?.createEndAt;
      moment(i)?.diff(queryVariables?.createStartAt, unit) >= 0;
      i = moment(i).add(-1, unit)?.startOf(unit)
    ) {
      date[moment(i).valueOf()] = [moment(i).valueOf(), 0]
    }

    setOptions((x: any) => ({
      ...x,
      xAxis: {
        ...initAlarmBarChart.xAxis,
        type: 'datetime',
        categories: data.slice(startIdx, endIdx).map((i: any) => i[0]),
        labels: {
          formatter: function (this: any) {
            return unit === 'month'
              ? moment(this.value).format('YYYY-MM')
              : moment(this.value).format('YYYY-MM-DD')
          },
        },
      },
      yAxis: {
        title: {
          text: t('report:total number of repair yAxis Label'),
        },
      },
      series: [
        {
          name: t('report:total number of repair'),
          data: data.slice(startIdx, endIdx).map((i: any) => i[1]),
        },
      ],
      tooltip: {
        ...initAlarmBarChart.tooltip,
        formatter() {
          const _this = this as any
          return [''].concat(
            _this.points
              ? _this.points.map(function (val: any) {
                  return (
                    `<b style="font-size:13px; color:${theme.tooltip.color};font-weight:500"><div style="margin-bottom:8px;">` +
                    moment(val.x).format(
                      unit === 'month' ? 'YYYY-MM' : 'YYYY-MM-DD'
                    ) +
                    '</div></b>' +
                    tooltipPointFormat(val, false, t('report:count'))
                  )
                })
              : []
          )
        },
      },
    }))
  }, [data, currentChart]) // eslint-disable-line

  const handleNextPage = React.useCallback(() => {
    const idx = currentChart + 1

    setStartIdx(PAGE_SIZE * idx)
    setCurrentChart(idx)
  }, [currentChart])

  const handlePrevPage = React.useCallback(() => {
    const idx = currentChart - 1

    setStartIdx(PAGE_SIZE * idx)
    setCurrentChart(idx)
  }, [currentChart])

  React.useEffect(() => {
    if (isEmpty(query)) {
      return
    }

    setStartIdx(0)
    setCurrentChart(0)
  }, [query]) // eslint-disable-line

  React.useEffect(() => {
    if (!loading) {
      chart?.current?.chart?.hideLoading()
      chart?.current?.chart?.setSize(null, null)
    } else {
      chart?.current?.chart?.showLoading()
    }
  }, [chart, loading])

  return (
    <>
      <Row gutter={[48, 48]} className="mr-40">
        <Col span={24}>
          <Pagination
            className="my-40 ml-[65px]"
            page={currentChart + 1}
            totalPages={Math.ceil(data.length / PAGE_SIZE)}
            onNextPage={handleNextPage}
            onPrevPage={handlePrevPage}
          />
        </Col>
        <Col span={24}>
          <Spin spinning={loading}>
            <Box position="relative">
              <Box
                position="absolute"
                top="-35px"
                left="65px"
                zIndex={1}
                fontSize="18px"
                className="font-medium text-report-color">
                {t('report:count')}
              </Box>

              <HighchartsReact
                ref={chart}
                highcharts={Highcharts}
                containerProps={{ style: { width: '100%', height: '100%' } }}
                options={options}
              />

              {query.reportType !== TYPE.DAY ? (
                <Box
                  position="absolute"
                  className="font-medium"
                  right="0px"
                  fontSize="14px">
                  {yAxisDateStr}
                </Box>
              ) : (
                t('report:days')
              )}
            </Box>
          </Spin>
        </Col>
        <ChartTitle {...props} />
      </Row>
    </>
  )
}

export default React.memo(MaintenanceChart)
