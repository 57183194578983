import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { createPortal } from 'react-dom'
import { useGoogleMap, GoogleMap } from '@react-google-maps/api'
import LayerIcon from './LayerIcon'
import { useInnmaxMap } from '@shared/InnmaxMap2/Provider'

export type SatelliteControlProps = {
  position?: google.maps.ControlPosition
  styles?: React.CSSProperties
  zIndex?: string
  defaultToogleMapTypeId?: google.maps.MapTypeId
}

function SatelliteControl({
  position = window.google.maps.ControlPosition.BOTTOM_LEFT,
  styles = {},
  defaultToogleMapTypeId = google.maps.MapTypeId.SATELLITE,
}: SatelliteControlProps) {
  const { t } = useTranslation()

  const map: google.maps.Map | null = useGoogleMap()

  const { defaultCenter, defaultZoom } = useInnmaxMap()

  const [center, setCenter] = React.useState<
    google.maps.LatLng | google.maps.LatLngLiteral | undefined
  >(defaultCenter)
  const [zoom, setZoom] = React.useState<number>(defaultZoom)

  const [toogleMapTypeId, setToogleMapTypeId] =
    React.useState<google.maps.MapTypeId>(defaultToogleMapTypeId)

  const [container] = React.useState(document.createElement('div'))

  React.useEffect(() => {
    const controlsContainer: any = map?.controls[position]
    controlsContainer.push(container)
    return () => {
      const index = controlsContainer.indexOf(container)
      if (index !== -1) {
        controlsContainer.removeAt(index)
      }
    }
  }, [map]) // eslint-disable-line

  React.useEffect(() => {
    if (!map) {
      return
    }

    const center_changed = () => {
      setCenter(map.getCenter())
    }

    const zoom_changed = () => {
      setZoom(map.getZoom() || defaultZoom)
    }

    const maptypeid_changed = () => {
      setToogleMapTypeId(
        map.getMapTypeId() === google.maps.MapTypeId.ROADMAP
          ? google.maps.MapTypeId.SATELLITE
          : google.maps.MapTypeId.ROADMAP
      )
    }

    const centerListener = map.addListener('center_changed', center_changed)
    const zoomListener = map.addListener('zoom_changed', zoom_changed)
    const maptypeidListener = map.addListener(
      'maptypeid_changed',
      maptypeid_changed
    )

    return () => {
      centerListener.remove()
      zoomListener.remove()
      maptypeidListener.remove()
    }
  }, [defaultZoom, map])

  React.useEffect(() => {
    for (const [key, value] of Object.entries(styles as any)) {
      ;(container.style as any)[key] = value
    }
  }, [styles]) // eslint-disable-line

  const toggleLayerType = () => {
    const newLayerType =
      map?.getMapTypeId() === google.maps.MapTypeId.ROADMAP
        ? google.maps.MapTypeId.SATELLITE
        : google.maps.MapTypeId.ROADMAP
    map?.setMapTypeId(newLayerType)
  }

  const title = React.useMemo(
    () =>
      toogleMapTypeId === google.maps.MapTypeId.ROADMAP
        ? t('dashboard:map')
        : t('dashboard:satellite'),
    [toogleMapTypeId]
  ) // eslint-disable-line

  return createPortal(
    <StyledControl title={title}>
      <GoogleMap
        id="satellite-control"
        onClick={toggleLayerType}
        center={center}
        zoom={zoom / 2}
        mapContainerStyle={{ height: '100%' }}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          mapTypeId: toogleMapTypeId,
          draggable: false,
        }}>
        <StyledMapType>
          <LayerIcon />
          <span>
            {toogleMapTypeId === google.maps.MapTypeId.ROADMAP
              ? t('dashboard:map')
              : t('dashboard:satellite')}
          </span>
        </StyledMapType>
      </GoogleMap>
    </StyledControl>,
    container
  )
}

const StyledControl = styled.div`
  user-select: none;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  width: 72px;
  height: 72px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border: solid 2px #fff;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    border-color: #d1e0e7;
  }

  div[aria-label='地圖'] {
    cursor: pointer !important;
  }
`

const StyledMapType = styled.div`
  display: inline-flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  background: #0d11284d;

  span {
    margin-left: 5px;
  }
`

export default React.memo(SatelliteControl)
