import { isNil } from 'ramda'
import React from 'react'
import GoogleMap from '../Google'
import { useInnmaxMapInstance } from '../hooks'
import { InnmaxMap2Props, MapType } from '../map.types'
import MapLibre from '../MapLibre'
import { useMapLibre } from '../MapLibre/Provider'
import {
  IInnmaxMapProviderProps,
  InnmaxMapProvider,
  useInnmaxMap,
} from '../Provider'
import { InnmaxMapTool } from '../tools'

const MapComponent = React.forwardRef(
  (
    props: InnmaxMap2Props & IInnmaxMapProviderProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const {
      mapPlatform,
      setMapPlatform,
      setMapType,
      setFocusDevice,
      setSelectedDevices,
      focusDevice,
      selectedDevices,
      googleMap,
      maplibreMap,
    } = useInnmaxMap()

    const {
      googleMapOptions,
      mapLibreOptions,
      defaultCenter,
      defaultZoom,
      children,
      mapType,
      mapInstance,
      ...others
    } = props

    const [_mapInstance] = useInnmaxMapInstance(mapInstance)

    const { projectToRatioPoints } = useMapLibre()

    React.useEffect(() => {
      if (isNil(_mapInstance)) {
        return
      }
      _mapInstance.setFocusDevice = setFocusDevice
      _mapInstance.setSelectedDevices = setSelectedDevices
      _mapInstance.selectedDevices = selectedDevices
      _mapInstance.focusDevice = focusDevice
      _mapInstance.projectToRatioPoints = projectToRatioPoints
    }, [
      _mapInstance,
      focusDevice,
      selectedDevices,
      setFocusDevice,
      setSelectedDevices,
      projectToRatioPoints,
    ])

    React.useEffect(() => {
      if (isNil(_mapInstance)) {
        return
      }
      if (mapType === MapType.Upload || mapPlatform === 'MapLibre') {
        _mapInstance.map = maplibreMap
      } else if (mapPlatform === 'Google') {
        _mapInstance.map = googleMap
      }
    }, [_mapInstance, googleMap, mapPlatform, mapType, maplibreMap])

    React.useEffect(() => {
      if (isNil(focusDevice)) {
        return
      }
      if (mapPlatform !== 'Google' || mapType !== MapType.GoogleMap) {
        return
      }
      panToRadiusBounds(
        googleMap as google.maps.Map,
        {
          lat: focusDevice.lat as number,
          lng: focusDevice.lon as number,
        },
        500
      )
    }, [focusDevice, mapType])

    React.useEffect(() => {
      InnmaxMapTool.addEventListener('mapPlatformChange', setMapPlatform)

      return () => {
        InnmaxMapTool.removeEventListener('mapPlatformChange', setMapPlatform)
      }
    }, [setMapPlatform])

    React.useEffect(() => {
      mapType && setMapType(mapType)
    }, [mapType])

    const Map = React.useMemo(() => {
      if (mapType === MapType.Upload || mapPlatform === 'MapLibre') {
        return (
          <MapLibre
            ref={ref}
            children={children}
            mapType={mapType}
            {...others}
            {...mapLibreOptions}
          />
        )
      } else if (mapPlatform === 'Google') {
        return (
          <GoogleMap
            ref={ref}
            children={children}
            {...others}
            {...googleMapOptions}
          />
        )
      }
      return <></>
    }, [children, mapType, others.layerFileUrl])

    return Map
  }
)

const InnternalInnmaxMapV2ForwardRef = React.forwardRef(
  (
    props: InnmaxMap2Props & IInnmaxMapProviderProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const {
      googleMapOptions,
      mapLibreOptions,
      defaultCenter,
      defaultZoom,
      children,
      mapType,
      ...others
    } = props

    return (
      <InnmaxMapProvider
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        mapType={mapType}>
        <MapLibre.Provide>
          <GoogleMap.Provider>
            <MapComponent ref={ref} {...props} />
          </GoogleMap.Provider>
        </MapLibre.Provide>
      </InnmaxMapProvider>
    )
  }
)

export const InnternalInnmaxMapV2 = React.memo(
  InnternalInnmaxMapV2ForwardRef,
  (prevProps, nextProps) => {
    if (prevProps.children !== nextProps.children) {
      return false
    }
    if (prevProps.mapType !== nextProps.mapType) {
      return false
    }
    if (prevProps.layerFileUrl !== nextProps.layerFileUrl) {
      return false
    }

    return true
  }
)

function panToRadiusBounds(
  map: google.maps.Map,
  centerLatLng: google.maps.LatLngLiteral,
  radiusInMeters: number
) {
  // Create a circle to get the bounds
  const circle = new google.maps.Circle({
    center: centerLatLng,
    radius: radiusInMeters,
  })

  // Get the bounds of the circle
  const bounds = circle.getBounds() as google.maps.LatLngBounds

  // Pan to the bounds with a smooth transition
  map.panToBounds(bounds)
  map.fitBounds(bounds)
}
